'use client';

import React, { FC, JSX, PropsWithChildren, Suspense } from 'react';
import { ApiImage } from '../../../api/model';
import HeroInner, { HeroInnerProps } from '@/components/hero/HeroInner';
import { mapToApiImage } from '@/Util/globals';
import { Replace } from '@/types/utlilities';
import { EditableAreaOrPageChild, MagnoliaDamNode } from '@/types/cms/magnolia';
import Search from '@/components/Search/Search';

export type HeroProps = Partial<EditableAreaOrPageChild> &
  Replace<HeroInnerProps, 'contentimage', MagnoliaDamNode | ApiImage | undefined>;

const Hero: FC<PropsWithChildren<HeroProps>> = (props): JSX.Element => {
  const {
    contentvideo,
    contentimage,
    content,
    headline,
    tagline,
    useVideoScaleEffect,
    headlineType,
    showSearchPanel,
    hideScrollBtn,
    smallVersion,
    liftContent,
    additionalContent,
    children,
    productData,
    pageProps,
    language,
    pdfCoverImage,
    useDefaultLoader
  } = props;

  return (
    <HeroInner
      contentimage={mapToApiImage(contentimage)}
      contentvideo={contentvideo}
      content={content}
      headline={headline}
      headlineType={headlineType}
      tagline={tagline}
      useVideoScaleEffect={useVideoScaleEffect}
      hideScrollBtn={hideScrollBtn}
      smallVersion={smallVersion}
      liftContent={liftContent}
      additionalContent={additionalContent}
      productData={productData}
      pageProps={pageProps}
      language={language}
      pdfCoverImage={pdfCoverImage}
      useDefaultLoader={useDefaultLoader}
    >
      <Suspense>{showSearchPanel && <Search isInitialSearch pageProps={pageProps} />}</Suspense>
      {children}
    </HeroInner>
  );
};

export default Hero;
