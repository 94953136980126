'use client';

import React, { FC, JSX, useRef, useState } from 'react';
import {
  DefaultPageProps as BasicAreaPageLayoutProps,
  MagnoliaDamNode,
  MagnoliaNode
} from '@/types/cms/magnolia';
import ContactBar from '@/components/contactBar/ContactBar';
import { THEMES } from '@/Util/globals';
import { PageComponentProps } from '@/components/magnoliaPage/componentHelper';
import { HeroProps } from '@/components/hero/Hero';
import { ApiVideo } from '../../../api/model';
import CheckoutPageInner from '@/templates/checkout/CheckoutPageInner';
import CheckoutPageHero from '@/templates/checkout/CheckoutPageHero';
import { CheckoutContextProvider } from '@/templates/checkout/CheckoutContextProvider';
import CheckoutHeader from '@/templates/checkout/CheckoutHeader';
import classNames from 'classnames';
import useBackendData from '@/templates/checkout/useBackendData';
import SuspenseFallbackComponent from '@/components/SuspenseFallbackComponent/SuspenseFallbackComponent';
import { usePathname, useSearchParams } from 'next/navigation';
import { PacificProductForHero } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';
import { fallbackLng } from '@/app/i18n/settings';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { useApi } from '@/Hooks/useApi';
import { useWindow } from '@ibe/components';

export type CheckoutParams = {
  packageId: string;
  displayUrl: string;
  productCode: string;
  cacheDataProductCode: string;
  variantParam: string;
};

export interface CheckoutProps extends BasicAreaPageLayoutProps, PageComponentProps {
  themeContactInformation: string;
  showContactBar: boolean;
  heroBackgroundvideo: ApiVideo;
  heroBackgroundimage: MagnoliaDamNode;
  content: HeroProps['content'];
  privacyPolicyUrl: MagnoliaNode;
  regulationsUrl: MagnoliaNode;
  tAndCInsuranceUrl: string;
  adobeReaderUrl: string;
  useParticipantsMock: boolean;
}

const CheckoutPageClient: FC<CheckoutProps> = (props): JSX.Element => {
  const {
    themeContactInformation,
    showContactBar,
    privacyPolicyUrl,
    regulationsUrl,
    adobeReaderUrl,
    tAndCInsuranceUrl,
    header,
    pageProps,
    useParticipantsMock
  } = props;
  const searchParams = useSearchParams();
  const { siteConfig, rootNodePath, isAuthor, locale = fallbackLng } = pageProps || {};
  const api = useApi(isAuthor);
  const pathname = usePathname();
  const window = useWindow();
  const [pacificProduct, setPacificProduct] = useState<PacificProductForHero | undefined>(
    undefined
  );
  const checkoutStoreRef = useRef<CheckoutStore>(
    new CheckoutStore(api, window, pathname, siteConfig, locale)
  );

  const { backendData, isLoading } = useBackendData(searchParams, isAuthor, pageProps);
  const { selectedPacificProduct, product, variantParam, confirmationPageMode } = backendData || {};
  const pacificProductForHero = pacificProduct?.product || selectedPacificProduct;

  return isLoading ? (
    <SuspenseFallbackComponent logoOnly fixedPosition />
  ) : (
    <CheckoutContextProvider>
      <div className={classNames('page__top', { 'page__top--regular': confirmationPageMode })}>
        <CheckoutHeader header={header} />
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">
        {pacificProductForHero && (
          <CheckoutPageHero
            selectedPacificProduct={pacificProductForHero}
            name={pacificProduct?.name}
            locale={locale}
            product={product}
            pageProps={pageProps}
          />
        )}
        {backendData && (
          <CheckoutPageInner
            pageProps={pageProps}
            checkoutStore={checkoutStoreRef.current}
            backendData={backendData}
            setPacificProduct={setPacificProduct}
            showContactBar={showContactBar}
            siteConfig={siteConfig}
            rootNodePath={rootNodePath}
            externalLinks={{
              privacyPolicyUrl,
              regulationsUrl,
              adobeReaderUrl,
              tAndCInsuranceUrl
            }}
            useParticipantsMock={useParticipantsMock}
            variantParam={variantParam}
          />
        )}
      </div>
    </CheckoutContextProvider>
  );
};

export default CheckoutPageClient;
