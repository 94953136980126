import { useEffect, useState } from 'react';

const useDelayRender = (delay: number = 1000): boolean => {
  const [renderNow, setRenderNow] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout((): void => {
      setRenderNow(true);
    }, delay);

    return (): void => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return renderNow;
};

export default useDelayRender;
