'use client';

import React, { FC } from 'react';
import { ApiProductsCacheData } from '@ibe/api';
import PacificBaseInfos from '@/components/checkout/cart/PacificBaseInfos';

const HeroPacificProductInfos: FC<{
  pacificProduct: ApiProductsCacheData;
}> = ({ pacificProduct }): JSX.Element => {
  return <PacificBaseInfos pacificProduct={pacificProduct} className="checkout__infos__product" />;
};

export default HeroPacificProductInfos;
