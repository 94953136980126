'use client';

import React, { JSX, useState } from 'react';
import { EditableAreaOrPageChild, MagnoliaNode } from '@/types/cms/magnolia';
import { logger, THEMES } from '@/Util/globals';
import { fallbackLng } from '@/app/i18n/settings';
import { ApiProduct } from '../../../api/model';
import TeasersSearchPageButton from '@/components/productTeasers/TeasersSearchPageButton';
import ProductTeasersCarousel from '@/components/productTeasers/ProductTeasersCarousel';
import { useModelApiClient } from '@/Util/api';
import { useMount } from 'react-use';

type ProductsField = MagnoliaNode & { field: ApiProduct };
const NUMBER_OF_PRODUCTS_LIMIT = 10;

export interface ProductTeasersProps extends EditableAreaOrPageChild {
  theme: string;
  headline: string;
  getProductsBy: 'travelType' | 'country' | 'products' | 'continent' | 'city' | 'ship';
  getProductsBytravelTypeField?: string;
  getProductsBycountryField?: string;
  getProductsBycontinentField?: string;
  getProductsBycityField?: string;
  getProductsByShipField?: string;
  productsField?: MagnoliaNode & { [key: `productsField${number}`]: ProductsField };
  hideTravelsCTA?: boolean;
  excludeProducts?: string[];
}

const ProductTeasersInner = ({
  theme,
  headline,
  getProductsBytravelTypeField,
  getProductsBycountryField,
  getProductsBycontinentField,
  getProductsBycityField,
  getProductsByShipField,
  getProductsBy,
  productsField,
  hideTravelsCTA,
  excludeProducts,
  pageProps
}: ProductTeasersProps): JSX.Element => {
  const { locale, siteConfig, rootNodePath: currentRootNodePath } = pageProps || {};
  const modelApiClient = useModelApiClient(pageProps?.isAuthor);
  const [cmsProducts, setCmsProducts] = useState<ApiProduct[]>([]);

  useMount(
    async (): Promise<void> => {
      let products =
        !!productsField && getProductsBy === 'products'
          ? productsField['@nodes'].map(propertyName => {
              const product =
                productsField[propertyName as keyof ProductTeasersProps['productsField']]?.field;
              const variants = Object.entries(product)
                .filter(([, properties]) => properties['@nodeType'] === 'mgnl:productVariant')
                .map(([, properties]) => properties as ApiProduct);
              return { ...product, variants } as ApiProduct;
            })
          : [];

      try {
        if (getProductsBy === 'travelType' && !!getProductsBytravelTypeField) {
          products = await modelApiClient.getProductsByTravelType(
            getProductsBytravelTypeField || '',
            siteConfig?.name || '',
            locale || fallbackLng,
            NUMBER_OF_PRODUCTS_LIMIT
          );
        } else if (getProductsBy === 'country' && !!getProductsBycountryField) {
          products = await modelApiClient.getProductsByCountry(
            getProductsBycountryField || '',
            siteConfig?.name || '',
            locale || fallbackLng,
            NUMBER_OF_PRODUCTS_LIMIT
          );
        } else if (getProductsBy === 'continent' && !!getProductsBycontinentField) {
          products = await modelApiClient.getProductsByContinentId(
            getProductsBycontinentField,
            siteConfig?.name || '',
            locale || fallbackLng,
            NUMBER_OF_PRODUCTS_LIMIT
          );
        } else if (getProductsBy === 'city' && !!getProductsBycityField) {
          products = await modelApiClient.getProductsByCityId(
            getProductsBycityField,
            siteConfig?.name || '',
            locale || fallbackLng,
            NUMBER_OF_PRODUCTS_LIMIT
          );
        } else if (getProductsBy === 'ship' && !!getProductsByShipField) {
          products = await modelApiClient.getProductsByShip(
            getProductsByShipField,
            siteConfig?.name || '',
            locale || fallbackLng,
            NUMBER_OF_PRODUCTS_LIMIT
          );
        }
      } catch (err) {
        logger('error')('Unable to fetch products data: ', err);
      }

      if (!!excludeProducts && excludeProducts.length > 0) {
        products = products.filter(product => !excludeProducts.includes(product.name));
      }
      setCmsProducts(products);
    }
  );

  return cmsProducts.length > 0 ? (
    <div className={`product-teasers ${theme || THEMES.blue}`} id="productTeaser">
      <div className="product-teasers__container">
        <div className="product-teasers__headline__container">
          <h3 className="product-teasers__headline">{headline}</h3>
          {!hideTravelsCTA && <TeasersSearchPageButton />}
        </div>
        <ProductTeasersCarousel
          products={cmsProducts}
          siteConfig={siteConfig}
          rootNodePath={currentRootNodePath || ''}
          pageProps={pageProps}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ProductTeasersInner;
