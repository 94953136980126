import React, { FC, JSX, useMemo } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Props } from '@/types/cms/magnolia';
import { getCheapestProduct } from '@/components/Search/helpersProduct';
import ResultsListItem from '@/components/SearchForResults/ResultsListItem';
import { getProductPageBasePath } from '@/Util/globals';
import LazyLoad from 'react-lazyload';
import FadeIn from '@/components/SearchForResults/FadeIn';

const WebsiteSearchFull: FC<{
  results: ApiProductsCacheResponse[];
  pageProps?: Props;
  activeIndex: number;
}> = ({ results, pageProps, activeIndex }): JSX.Element => {
  const { rootNodePath, siteConfig } = pageProps || {};
  const tabIndex = 0;
  const sortedPackages = useMemo((): ApiProductsCacheResponse[] => {
    return results.sort((a: ApiProductsCacheResponse, b: ApiProductsCacheResponse) => {
      return (
        (getCheapestProduct(a)?.startingPriceAmount || 0) -
        (getCheapestProduct(b)?.startingPriceAmount || 0)
      );
    });
  }, [results]);

  return (
    <div
      style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list website-search-full__results__list--teasers"
    >
      {sortedPackages.length > 0 &&
        sortedPackages.map(productPackage => (
          <LazyLoad
            key={productPackage.packageCode}
            throttle={300}
            offset={400}
            height={420}
            scroll
            resize
          >
            <FadeIn>
              <ResultsListItem
                key={productPackage.packageCode}
                product={productPackage}
                productPageBasePath={getProductPageBasePath(rootNodePath || '', siteConfig)}
                activeView={0}
                pageProps={pageProps}
              />
            </FadeIn>
          </LazyLoad>
        ))}
    </div>
  );
};

export default WebsiteSearchFull;
