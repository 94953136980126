import React, { FC, JSX } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Props } from '@/types/cms/magnolia';
import FlipMove from 'react-flip-move';
import ResultListItemLazy from '@/components/SearchForResults/ResultListItemLazy';

const ResultsList: FC<{
  productPageBasePath: string;
  pageProps?: Props;
  activeView: number;
  sortedPackages: ApiProductsCacheResponse[];
}> = ({ productPageBasePath, pageProps, activeView, sortedPackages }): JSX.Element => {
  return (
    <FlipMove typeName={null} duration={500} delay={150} staggerDurationBy={10}>
      {sortedPackages.length > 0 &&
        (activeView === 0 || activeView === 1) &&
        sortedPackages.map(productPackage => (
          <ResultListItemLazy
            key={productPackage.packageCode}
            productPackage={productPackage}
            activeView={activeView}
            pageProps={pageProps}
            productPageBasePath={productPageBasePath}
          />
        ))}
    </FlipMove>
  );
};

export default ResultsList;
