import {
  ApiContinentComponent,
  ApiTravelTypeComponent,
  ReducerState,
  ReducerStateItem,
  ReducerStateItems
} from '@/components/Search/searchReducer/types';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import short from 'short-uuid';
import { ApiProductsCacheResponse } from '@ibe/api';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { SEARCH_QUERY_PARAMETER } from '@/Util/globals';
import { isSearchParamsQuery } from '@/types/typeGuards';
import { getMonthYear } from '@/components/Search/MonthSelectorSearchTab';

dayjs.extend(localeData);
const translator = short();

export type SearchParamsQuery = {
  travelType?: string[];
  date?: string[];
  continent?: { [id: string]: string[] };
};

export const hasSelectedItems = (checkboxState: ReducerState): boolean => {
  return (
    Object.entries(checkboxState.items).filter(
      ([, { type }]) => type === 'travelType' || type === 'date'
    ).length > 0 ||
    Object.entries(checkboxState.items).filter(
      ([, { type, countries }]) =>
        type === 'continent' && !!countries && Object.keys(countries).length > 0
    ).length > 0
  );
};

export const hasValidPropsForQuery = (items: ReducerStateItems): boolean => {
  return (
    Object.entries(items).filter(
      ([, item]) => item.type === 'travelType' || item.type === 'continent' || item.type === 'date'
    ).length > 0
  );
};

export function toUUID(id: string): string {
  return translator.toUUID(id);
}

export function fromUUID(id: string): string {
  return translator.fromUUID(id);
}

const getSearchParamsQueryFromCheckboxState = (items: ReducerStateItems): string => {
  if (!hasValidPropsForQuery(items)) {
    return '';
  }
  const query = Object.entries(items).reduce(
    (total: SearchParamsQuery, [id, stateItem]: [string, ReducerStateItem]): SearchParamsQuery => {
      if (stateItem.type === 'travelType' || stateItem.type === 'date') {
        return {
          ...total,
          [stateItem.type]: [
            ...(total[stateItem.type] || []),
            stateItem.type === 'travelType' ? fromUUID(id) : id
          ]
        };
      } else if (stateItem.type === 'continent') {
        return {
          ...total,
          continent: {
            ...total.continent,
            [fromUUID(id)]: [
              ...(total.continent?.[fromUUID(id)] || []),
              ...(!!stateItem.countries
                ? Object.entries(stateItem.countries).map(([countryId]) => fromUUID(countryId))
                : [])
            ]
          }
        };
      } else {
        return total;
      }
    },
    {}
  );
  if (
    (query.travelType && query.travelType.length > 0) ||
    (query.date && query.date.length > 0) ||
    (!!query.continent && Object.keys(query.continent).length > 0)
  ) {
    return JSON.stringify(query);
  } else {
    return '';
  }
};

export const getCheckboxStateItemsFromSearchParamsQuery = (
  searchParams: ReadonlyURLSearchParams,
  travelTypes: ApiTravelTypeComponent[],
  continents: ApiContinentComponent[],
  monthLabels: string[]
): ReducerState['items'] | null => {
  const query = searchParams.get(SEARCH_QUERY_PARAMETER);
  if (!searchParams.has(SEARCH_QUERY_PARAMETER) || !query) {
    return null;
  }
  const parsedQuery = JSON.parse(query);
  if (!isSearchParamsQuery(parsedQuery)) {
    return null;
  }
  let newCheckoutStateItems: ReducerState['items'] = {};
  if (!!parsedQuery.travelType) {
    parsedQuery.travelType.forEach(travelTypeId => {
      const foundTravelType = travelTypes.find(
        travelType => travelType.id === toUUID(travelTypeId)
      );
      if (!!foundTravelType) {
        newCheckoutStateItems = {
          ...newCheckoutStateItems,
          [toUUID(travelTypeId)]: {
            type: 'travelType',
            name: foundTravelType.name || ''
          }
        };
      }
    });
  }
  if (!!parsedQuery.date) {
    parsedQuery.date.forEach(dateId => {
      newCheckoutStateItems = {
        ...newCheckoutStateItems,
        [dateId]: {
          type: 'date',
          name: getMonthYear(dateId, monthLabels)
        }
      };
    });
  }
  if (!!parsedQuery.continent) {
    Object.entries(parsedQuery.continent).forEach(([continentId, countries]) => {
      const foundContinent = continents.find(continent => continent.id === toUUID(continentId));
      newCheckoutStateItems = {
        ...newCheckoutStateItems,
        [toUUID(continentId)]: {
          type: 'continent',
          name: '',
          countries: Object.fromEntries(
            countries.map(countryId => {
              const foundCountry = foundContinent?.countries?.find(
                country => country.id === toUUID(countryId)
              );
              return [toUUID(countryId), foundCountry?.name || ''];
            })
          )
        }
      };
    });
  }
  if (Object.keys(newCheckoutStateItems).length > 0) {
    return newCheckoutStateItems;
  } else {
    return null;
  }
};

export const getFilteredProductPackages = (
  checkboxState: ReducerState,
  singleRoomOnlyRadioChecked: boolean,
  hideUnavailableTripsChecked: boolean
): ApiProductsCacheResponse[] => {
  const { productPackagesFiltered } = checkboxState;
  return productPackagesFiltered.filter(productPackage =>
    productPackage?.products?.some(
      product =>
        (!singleRoomOnlyRadioChecked ||
          (singleRoomOnlyRadioChecked && product.minOccupancy === 1)) &&
        (!hideUnavailableTripsChecked ||
          (hideUnavailableTripsChecked && (product.availability || 0) > 0))
    )
  );
};

export const getUrlWithUpdatedQueryParams = (
  items: ReducerStateItems,
  searchParams: ReadonlyURLSearchParams,
  pathName?: string,
  additionalQueries?: [string, string][]
): string => {
  const searchParamsQuery = getSearchParamsQueryFromCheckboxState(items);
  const params = new URLSearchParams(searchParams.toString());
  if (params.has(SEARCH_QUERY_PARAMETER)) {
    params.delete(SEARCH_QUERY_PARAMETER);
  }
  if (!!searchParamsQuery) {
    params.set(SEARCH_QUERY_PARAMETER, searchParamsQuery);
  }
  if (!!additionalQueries && additionalQueries.length > 0) {
    additionalQueries.forEach(([key, value]) => params.set(key, value));
  }
  const queryString = params.toString();
  return `${pathName || ''}${!!queryString ? `?${queryString}` : ''}`;
};
