import React, { FC, JSX, useMemo } from 'react';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponse,
  ApiProductsCacheResponseVariants
} from '@ibe/api';
import { VARIANT_QUERY_PROP } from '@/Util/globals';
import { getSortedCacheProductsByDate } from '@/components/SearchForResults/helperFns';
import { ApiProduct } from '../../../api/model';
import { getCheapestProduct, getEarliestProduct } from '@/components/Search/helpersProduct';
import ResultsListItemContent from '@/components/SearchForResults/ResultsListItemContent';
import { Props } from '@/types/cms/magnolia';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';

export const getNumberOfProductsByDate = (productResponses: ApiProductsCacheResponse[]): number => {
  return Object.entries(getSortedCacheProductsByDate(productResponses))
    .flatMap(([, value]) => value)
    .reduce((total, current) => {
      const map: Record<string, boolean> = {};
      (current.products || []).forEach(product => (map[product.packageCode || ''] = true));
      return total + Object.keys(map).length;
    }, 0);
};

const ResultsListItem: FC<{
  product: ApiProductsCacheResponse;
  productPageBasePath: string;
  activeView: number;
  pageProps?: Props;
}> = ({ product, productPageBasePath, activeView, pageProps }): JSX.Element => {
  const {
    teaserImage,
    productTitle,
    products,
    variants,
    displayUrl,
    map,
    travelTypes,
    review
  } = product;

  const [earliestProduct, matchingVariant] = useMemo((): [
    ApiProductsCacheData | undefined,
    ApiProductsCacheResponseVariants | undefined
  ] => {
    const foundProduct = getEarliestProduct(product);
    const foundVariant = variants?.find(
      variant => !!foundProduct && foundProduct.packageCode === variant.packageCode
    );
    return [foundProduct, foundVariant];
  }, [product, variants]);

  const numberOfCacheProducts = useMemo((): number => {
    return getNumberOfProductsByDate([product]);
  }, [product]);

  const cheapestProduct = useMemo(
    (): ApiProductsCacheData | undefined =>
      getCheapestProduct(product, earliestProduct?.packageCode),
    [products, earliestProduct]
  );

  const hasAvailableProduct = useMemo((): boolean | undefined => {
    return products?.some(product => (product.availability || 0) > 0);
  }, [products]);

  const productDetailsLink = useMemo((): string => {
    if (!!productPageBasePath && !!displayUrl) {
      const productVariants = variants?.map(cacheVariant => {
        return {
          name: cacheVariant.packageCode
        } as ApiProduct;
      });
      const variantParam = getVariantParam(
        product,
        earliestProduct?.travelStartDate,
        productVariants,
        earliestProduct?.packageCode
      );
      return `/${productPageBasePath}/${displayUrl}${
        !!variantParam ? `?${VARIANT_QUERY_PROP}=${variantParam}` : ''
      }`;
    } else {
      return '#';
    }
  }, [productPageBasePath, displayUrl, variants, earliestProduct, product]);

  return (
    <ResultsListItemContent
      productPageBasePath={productPageBasePath}
      activeView={activeView}
      productDetailsLink={productDetailsLink}
      teaserImage={matchingVariant?.teaserImage || teaserImage}
      map={matchingVariant?.map || map}
      review={matchingVariant?.review || review}
      travelTypes={matchingVariant?.travelTypes || travelTypes}
      name={matchingVariant?.productTitle || productTitle}
      earliestProduct={earliestProduct}
      hasMoreThanOneDate={numberOfCacheProducts > 1}
      displayUrl={displayUrl}
      numberOfCacheProducts={numberOfCacheProducts}
      hasAvailableProduct={hasAvailableProduct}
      cheapestProduct={cheapestProduct}
      pageProps={pageProps}
    />
  );
};

export default ResultsListItem;
