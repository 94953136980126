import { getEventClasses } from '@/Tracking/trackingEvents';
import AlbConfigModel from '@/Config/AlbConfigModel';
import { CheckoutStepName, EventType, PageType, SearchCategory } from '@/Tracking/types';
import { Props } from '@/types/cms/magnolia';
import {
  ApiBooking,
  ApiFlightItem,
  ApiItemType,
  ApiPackageCart,
  ApiProductsCacheData,
  ApiProductsCacheResponse
} from '@ibe/api';
import { ApiProduct } from '../../api/model';
import dayjs from 'dayjs';
import { FAQ_TEMPLATE, logger } from '@/Util/globals';

export type BroadCastData = {
  data?: {
    pageProps?: Props;
    packageCart?: ApiPackageCart;
    booking?: ApiBooking;
    productPackage?: ApiProductsCacheResponse;
    cacheDataProduct?: ApiProductsCacheData;
    product?: ApiProduct;
    bookingPackageName?: string;
    bookingPackageCode?: string;
    doBroadcastHomePageSearchEvent?: boolean;
  };
  search?: { searchCategory?: SearchCategory; searchTerm?: string };
  checkout?: { step: number; stepName: string };
};

const getPageType = (pageProps?: Props, is404?: boolean): PageType | string => {
  const {
    magnoliaContext: { nodePath = '' } = {},
    siteConfig: { parameters } = { parameters: undefined }
  } = pageProps || {};
  let pageType = pageProps?.page?.pageType || '';
  if (!pageType && !!nodePath && !!parameters) {
    if (is404) {
      return PageType.ERROR_PAGE;
    } else if (nodePath.startsWith(parameters.productPage)) {
      return PageType.TOUR;
    } else if (nodePath.startsWith(parameters.travelTypePage)) {
      return PageType.THEME;
    } else if (nodePath.split('/')[2] === parameters.destinationPage.split('/')[2]) {
      return PageType.WORLD_PART;
    } else if (nodePath.startsWith(parameters.resultsPage)) {
      return PageType.TOUR_SEARCH;
    } else if (nodePath.startsWith(parameters.websiteSearchPage)) {
      return PageType.WEBSITE_SEARCH;
    } else if (nodePath.startsWith(parameters.checkoutPage)) {
      return PageType.BOOKING_FORM;
    } else if (pageProps?.page?.['mgnl:template'] === FAQ_TEMPLATE) {
      return PageType.FAQ_PAGE;
    } else {
      return PageType.HOME;
    }
  }
  return pageType || PageType.HOME;
};

export const broadcastEvent = (
  eventType: EventType,
  config: AlbConfigModel,
  locale: string,
  broadCastData?: BroadCastData
): void => {
  const {
    pageViewEvent,
    searchEvent,
    beginCheckoutEvent,
    checkoutProgressEvent,
    purchaseEvent
  } = getEventClasses(config, locale);
  const {
    data: {
      pageProps,
      packageCart,
      booking,
      productPackage,
      product: checkoutProduct,
      cacheDataProduct,
      bookingPackageName,
      bookingPackageCode,
      doBroadcastHomePageSearchEvent
    } = {
      pageProps: undefined,
      packageCart: undefined,
      booking: undefined,
      productPackage: undefined,
      cacheDataProduct: undefined,
      product: undefined,
      bookingPackageName: undefined,
      bookingPackageCode: undefined,
      doBroadcastHomePageSearchEvent: undefined
    },
    search: { searchCategory, searchTerm } = { searchCategory: undefined, searchTerm: undefined },
    checkout: { step, stepName } = { step: -1, stepName: '' }
  } = broadCastData || {};
  const {
    continent,
    country,
    city,
    travelType,
    product,
    is404,
    magnoliaContext: { nodePath } = {}
  } = ({} = pageProps || {});
  const { travelTypes, continents, countries, cities, productTitle, name } = product || {};
  const pageType = getPageType(pageProps, is404);
  switch (eventType) {
    case EventType.PAGE_VIEW:
      pageViewEvent.broadcast({
        event: EventType.PAGE_VIEW,
        pageType,
        worldPart: continent?.name || continents?.map(continent => continent.name)?.join(', '),
        country: country?.name || countries?.map(country => country.name)?.join(', '),
        city: city?.name || cities?.map(city => city.name)?.join(', '),
        theme:
          travelType?.name ||
          travelTypes?.map(travelType => travelType.name)?.join(', ') ||
          (!!nodePath && pageType === PageType.THEME
            ? nodePath.split('/').slice(3).join(', ')
            : undefined),
        airport:
          productPackage?.products?.[0]?.departureAirport || cacheDataProduct?.departureAirport,
        currency:
          productPackage?.products?.[0]?.startingPriceCurrency ||
          packageCart?.packageModel?.packageDetails?.[0]?.price?.currencyCode,
        price:
          productPackage?.products?.[0]?.startingPriceAmount ||
          packageCart?.packageModel?.packageDetails?.[0]?.price?.finalPrice,
        roundTripName: productTitle || checkoutProduct?.productTitle,
        roundTripCode: name || checkoutProduct?.name,
        duration: productPackage?.products?.[0]?.duration || cacheDataProduct?.duration
      });
      break;
    case EventType.SEARCH:
      if (pageType !== PageType.HOME || doBroadcastHomePageSearchEvent) {
        searchEvent.broadcast({
          event: EventType.SEARCH,
          pageType,
          searchCategory: searchCategory || SearchCategory.TOUR,
          ...(searchCategory === SearchCategory.WEBSITE ? { searchTerm } : {})
        });
      }
      break;
    case EventType.BEGIN_CHECKOUT:
      beginCheckoutEvent.broadcast({
        event: EventType.BEGIN_CHECKOUT,
        pageType: PageType.BOOKING_FORM,
        airport: cacheDataProduct?.departureAirport,
        currency: packageCart?.packageModel?.packageDetails?.[0]?.price?.currencyCode,
        price:
          (packageCart?.packageModel?.packageDetails?.[0]?.price?.finalPrice || 0) /
          (booking?.travelers?.length || cacheDataProduct?.maxOccupancy || 1),
        roundTripName: checkoutProduct?.productTitle,
        roundTripCode: checkoutProduct?.name,
        duration: cacheDataProduct?.duration
      });
      break;
    case EventType.PURCHASE:
      const startDate = dayjs(booking?.travelStartDate);
      const endDate = dayjs(booking?.travelEndDate);
      let amount = 0;
      if (startDate.isValid() && endDate.isValid()) {
        amount = endDate.diff(startDate, 'day');
      }
      purchaseEvent.broadcast({
        event: EventType.PURCHASE,
        pageType: PageType.BOOKING_FORM,
        airport: (booking?.items?.find(
          item => item.itemType === ApiItemType.FLIGHT
        ) as ApiFlightItem)?.segment?.[0]?.origin?.description,
        currency: booking?.price?.currencyCode,
        price:
          (booking?.price?.finalPrice || 0) /
          (booking?.travelers?.length || cacheDataProduct?.maxOccupancy || 1),
        roundTripName: bookingPackageName,
        roundTripCode: bookingPackageCode,
        duration: amount,
        customerCity: booking?.travelers?.[0]?.address?.city,
        customerZipCode: booking?.travelers?.[0]?.address?.postalCode,
        pax: booking?.travelers?.length || cacheDataProduct?.maxOccupancy,
        transactionId: booking?.bookingNumber
      });
      break;
    case EventType.CHECKOUT_PROGRESS:
      checkoutProgressEvent.broadcast({
        event: EventType.CHECKOUT_PROGRESS,
        pageType: PageType.BOOKING_FORM,
        checkoutStep: step,
        checkoutStepName: stepName,
        pax: booking?.travelers?.length || cacheDataProduct?.maxOccupancy,
        ...(!!checkoutProduct
          ? {
              product: {
                name: checkoutProduct.productTitle,
                price:
                  (booking?.price?.finalPrice || 0) /
                  (booking?.travelers?.length || cacheDataProduct?.maxOccupancy || 1),
                currency: booking?.price?.currencyCode
              }
            }
          : {}),
        ...(stepName === CheckoutStepName.DETAILS && booking
          ? {
              extras: booking.bookedItems
                .filter(bookedItem => bookedItem.itemType === ApiItemType.EXTRA)
                .map(item => ({
                  name: item.name,
                  price: item.price.finalPrice,
                  currency: item.price.currencyCode
                }))
            }
          : {})
      });
      break;
    default:
      logger('error')(`Tracking error: ${eventType} event does not exist...`);
  }
};

const initTrackingSubscriptions = (config: AlbConfigModel, locale: string): void => {
  const {
    pageViewEvent,
    searchEvent,
    beginCheckoutEvent,
    checkoutProgressEvent,
    purchaseEvent
  } = getEventClasses(config, locale);

  pageViewEvent.subscribe();
  searchEvent.subscribe();
  beginCheckoutEvent.subscribe();
  checkoutProgressEvent.subscribe();
  purchaseEvent.subscribe();
};

export default initTrackingSubscriptions;
