import { ApiService } from '@ibe/services';
import { useApi as useLibApi } from '@ibe/components';
import {
  FetchParams,
  Middleware as IbeApiMiddleware,
  RequestContext as IbeApiRequestContext
} from '@ibe/api';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

export const useApi = (isAuthor?: boolean): ApiService => {
  const { activateCache } = useGlobalMGLProps() || {};
  return useLibApi().withMiddleware(
    new (class implements IbeApiMiddleware {
      async pre(context: IbeApiRequestContext): Promise<void | FetchParams> {
        return {
          ...context,
          init: {
            ...context.init,
            cache: undefined,
            next: { revalidate: isAuthor && !activateCache ? 0 : 600 }
          }
        };
      }
    })()
  );
};
