import React, { ReactElement, useEffect, useState } from 'react';
import FactSheet from '@/components/introduction/FactSheet';
import sanitize from '@/Util/sanitize';
import Keys from '@/Translations/generated/da/product.json.keys';
import Image from '@/components/image/Image';
import { getDefaultFormatPrice, PRODUCT_AVAILABILITY_STATE, THEMES } from '@/Util/globals';
import IsoModal from '@/components/IsoModal';
import { useTransition } from 'react-transition-state';
import { useTranslation } from '@/app/i18n/client';
import GridContainer from '@/Layouts/GridContainer';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import useConfig from '@/Hooks/useConfig';
import dayjs from 'dayjs';
import { ApiFactSheet, ApiHighlight, ApiImage } from '../../../api/model';
import { ApiProductsCacheData, ApiProductWithReviews } from '@ibe/api';
import Highlights from '@/components/introduction/Highlights';
import { AspectRatio } from '@/Util/imageLoader';
import ProductReviewsCompact from '@/components/ProductReviewsCompact/ProductReviewsCompact';
import classNames from 'classnames';
import { Props } from '@/types/cms/magnolia';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';

const Introduction = ({
  cacheProduct,
  newProduct,
  theme,
  factSheet,
  map,
  tripDescription,
  introductionTitle,
  departureDatesAnchor,
  highlights,
  themeHighlights,
  aspectRatio,
  reviews,
  themeReviews,
  image,
  productsAvailabilityState,
  pageProps
}: {
  cacheProduct?: ApiProductsCacheData;
  name?: string;
  newProduct?: boolean;
  theme?: string;
  themeHighlights?: string;
  factSheet?: ApiFactSheet;
  map?: ApiImage;
  tripDescription?: string;
  introductionTitle?: string;
  highlights?: ApiHighlight[];
  departureDatesAnchor: string;
  aspectRatio: AspectRatio;
  reviews?: ApiProductWithReviews;
  themeReviews?: string;
  image?: ApiImage;
  productsAvailabilityState: PRODUCT_AVAILABILITY_STATE;
  pageProps?: Props;
}): ReactElement => {
  const { t } = useTranslation('product');
  const locale = useCurrentLanguage();
  const config = useConfig();
  const paragraphs = tripDescription?.split('\n\n');
  const firstParagraph = paragraphs ? paragraphs[0] : '';
  const remainingDescription = paragraphs ? paragraphs.slice(1).join('\n\n') : '';
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const isComponentMounted = useIsComponentMounted();

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 500,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggle(false);
  }, []);

  return (
    <>
      <GridContainer
        className={classNames(theme || '', {
          'introduction__grid--spacing': !!reviews?.reviews && reviews?.reviews?.length > 0
        })}
      >
        <div className="introduction__container">
          <h3 className="introduction__title d-xs-none d-md-block">{introductionTitle || ''}</h3>
          {productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail ||
            (productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.soldOutNoFutureAvail && (
              <div className="introduction__interest-list">
                <span>!</span>
                <span>{t(Keys.interestListTextOnProductPage)}</span>
              </div>
            ))}
          <div className="introduction__container__inner">
            <div className="introduction__factsheet">
              <FactSheet
                duration={`${cacheProduct?.duration?.toString()} ${t(Keys.days) ?? 'N/A'}`}
                price={
                  !!cacheProduct?.startingPriceAmount
                    ? `${getDefaultFormatPrice(cacheProduct?.startingPriceAmount || 0, locale)}`
                    : undefined
                }
                date={{
                  label:
                    !!cacheProduct?.travelStartDate && isComponentMounted
                      ? dayjs(cacheProduct.travelStartDate).format(config.displayFormatDate[locale])
                      : '',
                  anchorLink: departureDatesAnchor
                }}
                isNewTrip={newProduct}
                startingLocation={factSheet?.startingLocation || ''}
                transportation={factSheet?.transportation || ''}
                language={factSheet?.language || ''}
                tooltipText={factSheet?.priceTooltip || ''}
                theme={theme || ''}
                productsAvailabilityState={productsAvailabilityState}
              />
            </div>
            <h3 className="introduction__title d-md-none">{introductionTitle || ''}</h3>
            <div className="introduction__description">
              <div className="introduction__print">
                {!!image?.imageLink && (
                  <Image
                    src={image?.imageLink || ''}
                    alt=""
                    width={image?.width}
                    height={image?.height}
                    className="introduction__print__image"
                    serverSideContext={pageProps?.albConfig.context}
                  />
                )}
                <div
                  className="introduction__description--desktop"
                  dangerouslySetInnerHTML={{ __html: sanitize(tripDescription || '', true) }}
                />
                <div className="introduction__description--mobile">
                  <div dangerouslySetInnerHTML={{ __html: sanitize(firstParagraph || '', true) }} />
                  {isMounted && (
                    <div
                      className={`introduction__description--mobile__remaining${
                        status === 'preEnter' || status === 'exiting'
                          ? ' introduction__description--mobile__remaining--hidden'
                          : ''
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(remainingDescription || '', true)
                      }}
                    />
                  )}
                  {!!remainingDescription && (
                    <div
                      className="introduction__read-more"
                      onClick={(): void => toggle(!isMounted)}
                    >
                      {isMounted ? t(Keys.showLess) : t(Keys.readMore)}
                    </div>
                  )}
                </div>
              </div>
              <div className="introduction__map">
                {!!map && (
                  <div className="introduction__map__container">
                    <div
                      className="introduction__map__image"
                      onClick={(): void => setModalOpen(true)}
                    >
                      <Image
                        src={map.imageLink || ''}
                        alt={map.caption || ''}
                        width={256}
                        height={256}
                        aspectRatio={aspectRatio}
                        serverSideContext={pageProps?.albConfig.context}
                      />
                    </div>
                    <div className="introduction__map__text">{t(Keys.mapText)}</div>
                    <div className="introduction__map__headline">{t(Keys.highlights)}</div>
                    <div className="introduction__highlighttext">
                      {highlights?.map((highlight, index) => (
                        <div className="introduction__highlightitem" key={highlight.id}>
                          {index + 1}. {highlight.text}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </GridContainer>
      {!pageProps?.albConfig.hideFeaturedReviews && (
        <ProductReviewsCompact reviews={reviews} themeReviews={themeReviews} />
      )}
      {!!highlights && highlights.length > 2 && (
        <Highlights
          title={t(Keys.highlights)}
          theme={themeHighlights || THEMES.sand}
          highlights={highlights}
          pageProps={pageProps}
        />
      )}
      {!!map && (
        <IsoModal
          className="introduction__modal"
          toggle={(): void => setModalOpen(value => !value)}
          isOpen={modalOpen}
          onClose={(): void => setModalOpen(false)}
        >
          <Image
            src={map.imageLink || ''}
            alt={map.caption || ''}
            width={map.width}
            height={map.height}
            useDefaultLoader
            serverSideContext={pageProps?.albConfig.context}
          />
        </IsoModal>
      )}
    </>
  );
};

export default Introduction;
