'use client';

import React, { FC, JSX, useEffect } from 'react';
import Script from 'next/script';
import GridContainer from '@/Layouts/GridContainer';
import { PRODUCT_REVIEW_CONTAINER_ID } from '@/Util/globals';
import { ApiProduct } from '../../../api/model';
import { BVSrcReplacements } from '@/Config/AlbConfigModel';
import { fallbackLng, languageMapping } from '@/app/i18n/settings';

let timer: ReturnType<typeof setTimeout> | null = null;

const BVDetailedReviews: FC<{
  bazaarVoiceId: string;
  locale: string;
  product?: ApiProduct;
  bvSrc?: string;
  resolvedUrl?: string;
}> = ({ bazaarVoiceId, locale, product, bvSrc, resolvedUrl }): JSX.Element => {
  const onReady = () => {
    timer = setTimeout(() => {
      window.$BV?.ui('rr', 'show_reviews', {
        productId: bazaarVoiceId
      });

      if (!window.$BV?.ui) {
        window.BV?.ui('show_reviews', {
          productID: bazaarVoiceId,
          contentContainerDiv: 'bazaarvoice-product-reviews'
        });
      }
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    const albProductReviewContainer = document.getElementById(PRODUCT_REVIEW_CONTAINER_ID);
    const reviewsElement = document.getElementById('reviews');
    if (albProductReviewContainer && reviewsElement) {
      albProductReviewContainer.style.padding = '0';
      reviewsElement.style.minHeight = '0';
    }
    const showReviews = () => {
      if (albProductReviewContainer && reviewsElement) {
        const contentDiv = albProductReviewContainer.querySelector('div');
        if (contentDiv?.offsetHeight && contentDiv.offsetHeight > 0) {
          albProductReviewContainer.style.padding =
            'clamp(2rem, 2.857vw + 1.43rem, 4rem) clamp(1.5rem, 9.286vw + -0.36rem, 8rem)';
          reviewsElement.style.minHeight = '22rem';
          clearInterval(interval);
        }
      }
    };
    const interval = setInterval(showReviews, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <GridContainer id={PRODUCT_REVIEW_CONTAINER_ID}>
      <div
        id="bazaarvoice-product-reviews"
        data-bv-show="reviews"
        data-bv-product-id={bazaarVoiceId}
      />
      {!!bvSrc && (
        <>
          <Script
            id="bazaarvoice-reviews-script"
            src={bvSrc.replace(
              BVSrcReplacements.LOCALE,
              languageMapping[locale || fallbackLng].language.replace('-', '_')
            )}
            onReady={onReady}
          />
          {!!product && (
            <>
              <Script id="bazaarvoice-schema-script" type="application/ld+json">
                {`
              {
                "@context" : "https://schema.org",
                "@type" : "Product",
                "@id" : "${resolvedUrl || ''}",
                "name" : "${product.name || ''}",
                "image" : "${product.heroBackgroundImage?.imageLink || ''}",
                "description" : "${product.tripDescription || ''}",
              }
            `}
              </Script>
            </>
          )}
        </>
      )}
    </GridContainer>
  );
};

export default BVDetailedReviews;
