import React, { FC, JSX } from 'react';
import { ApiWebsiteFaqSearch } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link as NextLink } from '@/Hooks/useReactTransitionProgress';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';
import { WEBSITE_SEARCH_HEADLINE_PARAM } from '@/Util/globals';
import { useWindow } from '@ibe/components';
import LazyLoad from 'react-lazyload';
import FadeIn from '@/components/SearchForResults/FadeIn';

const WebsiteSearchFullFaqList: FC<{
  results: ApiWebsiteFaqSearch[];
  activeIndex: number;
}> = ({ results, activeIndex }): JSX.Element => {
  const { t } = useTranslation('Header');
  const window = useWindow();

  const tabIndex = 3;
  return (
    <div className="website-search-full__results__list">
      {results.map(r => {
        const textEncoded = new TextEncoder().encode(encodeURIComponent(r.question));
        const binaryString = String.fromCodePoint(...textEncoded);
        const baseString = window?.btoa(binaryString);
        return (
          <LazyLoad key={r.question} throttle={300} offset={400} height={420} scroll resize>
            <FadeIn>
              <div
                style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
                key={r.question}
                className="website-search-full__results__list--row"
              >
                <b>{r.question}</b>
                <NextLink
                  prefetch={false}
                  href={`${r.url}?${WEBSITE_SEARCH_HEADLINE_PARAM}=${baseString}`}
                >
                  <span>{t(Keys.learnMore)}</span>
                  <FontAwesomeIcon icon={faCircleArrowRight} />
                </NextLink>
                <p>{r.answer}</p>
              </div>
            </FadeIn>
          </LazyLoad>
        );
      })}
    </div>
  );
};

export default WebsiteSearchFullFaqList;
