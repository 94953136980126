import * as Yup from 'yup';
import {
  ApiBaseData,
  ApiGetBusinessPartnerRequestFromJSON,
  ApiTraveler,
  ApiTravelerType
} from '@ibe/api';
import { ApiService } from '@ibe/services';
import { createValidationSchema, FormRef } from '@ibe/components';
import countryCodes from '@/Util/countryCodes';
import { FormDataType, FormRefs } from '@/components/checkout/ParticipantsForm';
import React, { Dispatch, MutableRefObject, ReactElement, SetStateAction } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { logger, sanitizeString, TRAVELER_FORM_ADULT_LEGAL_MIN_AGE } from '@/Util/globals';
import { TFunction } from 'i18next';
import getTravelerFormConfig from '@/components/checkout/useTravelerFormConfig';
import { AnyObjectSchema, AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import { Language } from '@/Translations';
import { UseFormSetValue } from 'react-hook-form';

const findCountryCode = async (
  participant: FormRef<FormDataType>,
  country: string,
  countryCodeRef: MutableRefObject<Record<number, string>>,
  participantIndex: number
): Promise<void> => {
  if (!!participant) {
    const countryCode = countryCodes.find(code => code.code === country);
    if (!!countryCode) {
      participant.setValue('countryCode', countryCode.dial_code);
      countryCodeRef.current[participantIndex] = countryCode.dial_code;
      await participant.triggerFieldValidation('countryCode');
    }
  }
};

const fetchPostalCodes = async (
  postalCodes: string[],
  api: ApiService,
  locale: string,
  setPostalCodes: Dispatch<SetStateAction<Array<{ code: string; label: ReactElement }>>>,
  countryCode: string
): Promise<void> => {
  try {
    const baseData = await api.getCitiesAndCountriesFromPostalCode(
      postalCodes,
      !!countryCode ? [countryCode] : undefined,
      locale
    );
    if (baseData?.length > 0) {
      setPostalCodes(
        [...baseData].map((data: ApiBaseData) => ({
          code: data.code,
          fullObject: data,
          label: (
            <div className="postal-code-option">
              <span>{data.code}</span>
              <span>{` - ${data.description} (${
                data?.group?.split(',')[data.group.split(',').length - 1]
              })`}</span>
            </div>
          )
        }))
      );
    }
  } catch (err) {
    logger('error')('Unable to fetch postal codes: ', err);
  }
};

const checkFormValidity = async (
  validationSchema: Yup.AnyObjectSchema | Lazy<Yup.AnySchema<unknown, unknown, unknown>, unknown>,
  formValue: FormDataType,
  startDate: string,
  participantIndex: number,
  traveler?: ApiTraveler
): Promise<boolean> => {
  try {
    await validationSchema.validate(formValue, {
      abortEarly: false,
      context: {
        emailProp: 'email',
        startDate,
        adultMinAge:
          participantIndex === 0
            ? TRAVELER_FORM_ADULT_LEGAL_MIN_AGE
            : traveler?.type !== ApiTravelerType.ADULT
            ? traveler?.age || 0
            : 0
      }
    });
    return true;
  } catch (e) {
    logger('error')(e);
    return false;
  }
};

export const updateInsurances = async (
  checkoutStore: CheckoutStore,
  t: TFunction,
  setShowInsurances: Dispatch<SetStateAction<boolean>>,
  setInsurancesLoading: Dispatch<SetStateAction<boolean>>,
  participantsFormRefs: MutableRefObject<FormRefs>,
  locale: string
): Promise<void> => {
  {
    /* TODO: no insurances for FI until further notice */
  }
  if (locale === Language.FI || !checkoutStore.booking?.travelers) return;

  setInsurancesLoading(true);
  const validationSchemas = checkoutStore.booking.travelers.reduce(
    (
      total: (AnyObjectSchema | Lazy<AnySchema<unknown, unknown, unknown>, unknown>)[],
      traveler,
      idx
    ) => {
      const formConfig = getTravelerFormConfig(t, {
        isFirstTraveler: idx === 0,
        isChild: traveler.type !== ApiTravelerType.ADULT,
        adultMinAge:
          idx === 0
            ? TRAVELER_FORM_ADULT_LEGAL_MIN_AGE
            : traveler.type !== ApiTravelerType.ADULT
            ? traveler.age
            : 0,
        onRoyalAlbBlur: () => new Promise(a => a),
        participantIndex: idx
      });
      return [...total, createValidationSchema(formConfig.rows, formConfig.keySuffix)];
    },
    []
  );
  const formValues = participantsFormRefs.current.map(ref => ref?.getCurrentValues());
  if (formValues.length === (checkoutStore.selectedPacificProduct?.maxOccupancy || 1) + 1) {
    formValues.pop();
  }
  const promises = formValues.reduce((total: Promise<boolean>[], current, idx) => {
    return [
      ...total,
      checkFormValidity(
        validationSchemas[idx],
        current,
        checkoutStore.booking?.travelStartDate || '',
        idx,
        checkoutStore?.booking?.travelers?.[idx]
      )
    ];
  }, []);
  const res = await Promise.all(promises);
  if (res.every(value => value)) {
    try {
      await checkoutStore.createTravelers(formValues, true);
      await checkoutStore.changeComponentsForInsurances();
      setShowInsurances(true);
    } catch (e) {
      logger('error')(e);
      setShowInsurances(false);
    } finally {
      setInsurancesLoading(false);
    }
  } else {
    setShowInsurances(false);
    setInsurancesLoading(false);
  }
};

const sanitizeFormFields = (
  formFields: Record<string, string>,
  participantsFormRefs: MutableRefObject<FormRefs>,
  participantIndex: number,
  emailRef: MutableRefObject<Record<number, string>>,
  confirmEmailRef: MutableRefObject<Record<number, string>>,
  firstNameRef: MutableRefObject<Record<number, string>>,
  lastNameRef: MutableRefObject<Record<number, string>>,
  streetRef: MutableRefObject<Record<number, string>>,
  cityRef: MutableRefObject<Record<number, string>>,
  royalAlbNoRef: MutableRefObject<Record<number, string>>
): void => {
  if (!!formFields?.email && formFields.email !== emailRef.current[participantIndex]) {
    participantsFormRefs.current[participantIndex].setValue(
      'email',
      sanitizeString(formFields.email)
    );
    emailRef.current[participantIndex] = sanitizeString(formFields.email);
  }
  if (
    !!formFields?.confirmEmail &&
    formFields.confirmEmail !== confirmEmailRef.current[participantIndex]
  ) {
    participantsFormRefs.current[participantIndex].setValue(
      'confirmEmail',
      sanitizeString(formFields.confirmEmail)
    );
    confirmEmailRef.current[participantIndex] = sanitizeString(formFields.confirmEmail);
  }
  if (!!formFields?.firstName && formFields.firstName !== firstNameRef.current[participantIndex]) {
    participantsFormRefs.current[participantIndex].setValue(
      'firstName',
      sanitizeString(formFields.firstName)
    );
    firstNameRef.current[participantIndex] = sanitizeString(formFields.firstName);
  }
  if (!!formFields?.lastName && formFields.lastName !== lastNameRef.current[participantIndex]) {
    participantsFormRefs.current[participantIndex].setValue(
      'lastName',
      sanitizeString(formFields.lastName)
    );
    lastNameRef.current[participantIndex] = sanitizeString(formFields.lastName);
  }
  if (!!formFields?.street && formFields.street !== streetRef.current[participantIndex]) {
    participantsFormRefs.current[participantIndex].setValue(
      'street',
      sanitizeString(formFields.street)
    );
    streetRef.current[participantIndex] = sanitizeString(formFields.street);
  }
  if (!!formFields?.city && formFields.city !== cityRef.current[participantIndex]) {
    participantsFormRefs.current[participantIndex].setValue(
      'city',
      sanitizeString(formFields.city)
    );
    cityRef.current[participantIndex] = sanitizeString(formFields.city);
  }
  if (
    !!formFields?.royalAlbatros &&
    formFields.royalAlbatros !== royalAlbNoRef.current[participantIndex]
  ) {
    participantsFormRefs.current[participantIndex].setValue(
      'royalAlbatros',
      sanitizeString(formFields.royalAlbatros)
    );
    royalAlbNoRef.current[participantIndex] = sanitizeString(formFields.royalAlbatros);
  }
};

export const handleFormFieldsChange = async (
  formFields: Record<string, string>,
  participantIndex: number,
  api: ApiService,
  locale: string,
  setPostalCodes: Dispatch<SetStateAction<Array<{ code: string; label: ReactElement }>>>,
  countryRef: MutableRefObject<Record<number, string>>,
  zipCodeRef: MutableRefObject<Record<number, string>>,
  emailRef: MutableRefObject<Record<number, string>>,
  confirmEmailRef: MutableRefObject<Record<number, string>>,
  royalAlbNoRef: MutableRefObject<Record<number, string>>,
  phoneRef: MutableRefObject<Record<number, string>>,
  countryCodeRef: MutableRefObject<Record<number, string>>,
  ignoreCountryChangeForCountryCode: MutableRefObject<boolean>,
  participantsFormRefs: MutableRefObject<FormRefs>,
  firstNameRef: MutableRefObject<Record<number, string>>,
  lastNameRef: MutableRefObject<Record<number, string>>,
  streetRef: MutableRefObject<Record<number, string>>,
  cityRef: MutableRefObject<Record<number, string>>
): Promise<void> => {
  if (
    !!formFields?.country &&
    formFields.country !== countryRef.current[participantIndex] &&
    !ignoreCountryChangeForCountryCode.current
  ) {
    countryRef.current[participantIndex] = formFields.country;
    await findCountryCode(
      participantsFormRefs.current[participantIndex],
      formFields.country,
      countryCodeRef,
      participantIndex
    );
  }
  if (ignoreCountryChangeForCountryCode.current) {
    ignoreCountryChangeForCountryCode.current = false;
  }
  if (!!formFields?.zipCode && formFields.zipCode !== zipCodeRef.current[participantIndex]) {
    const newZipCode = sanitizeString(formFields.zipCode);
    participantsFormRefs.current[participantIndex].setValue('zipCode', newZipCode);
    zipCodeRef.current[participantIndex] = newZipCode;
    if (newZipCode.length > 1 && !!participantsFormRefs.current[participantIndex]) {
      await fetchPostalCodes(
        [`${newZipCode}%`],
        api,
        locale,
        setPostalCodes,
        countryRef.current[participantIndex]
      );
    }
  }
  if (
    (!!formFields.phone && formFields.phone !== phoneRef.current[participantIndex]) ||
    (!!formFields.countryCode &&
      formFields.countryCode !== countryCodeRef.current[participantIndex])
  ) {
    if (formFields.phone.includes(formFields.countryCode)) {
      const newValue = formFields.phone.replace(formFields.countryCode, '');
      participantsFormRefs.current[participantIndex].setValue('phone', sanitizeString(newValue));
      phoneRef.current[participantIndex] = sanitizeString(newValue);
    } else {
      participantsFormRefs.current[participantIndex].setValue(
        'phone',
        sanitizeString(formFields.phone)
      );
      phoneRef.current[participantIndex] = sanitizeString(formFields.phone);
    }
    participantsFormRefs.current[participantIndex].setValue(
      'countryCode',
      sanitizeString(formFields.countryCode)
    );
    countryCodeRef.current[participantIndex] = sanitizeString(formFields.countryCode);
  }

  sanitizeFormFields(
    formFields,
    participantsFormRefs,
    participantIndex,
    emailRef,
    confirmEmailRef,
    firstNameRef,
    lastNameRef,
    streetRef,
    cityRef,
    royalAlbNoRef
  );
};

export const getRoyalAlbatrosTraveler = async (
  emailRef: MutableRefObject<Record<number, string>>,
  confirmEmailRef: MutableRefObject<Record<number, string>>,
  royalAlbNoRef: MutableRefObject<Record<number, string>>,
  countryRef: MutableRefObject<Record<number, string>>,
  zipCodeRef: MutableRefObject<Record<number, string>>,
  participantsFormRefs: MutableRefObject<FormRefs>,
  participantIndex: number,
  api: ApiService,
  checkoutStore: CheckoutStore,
  countryCodeRef: MutableRefObject<Record<number, string>>
): Promise<void> => {
  const email = emailRef.current[participantIndex];
  const confirmEmail = confirmEmailRef.current[participantIndex];
  const royalAlbNo = royalAlbNoRef.current[participantIndex];

  if (
    email?.length > 4 &&
    email === confirmEmail &&
    !checkoutStore.booking?.travelers?.some(
      traveler => traveler.businessPartnerNumber === royalAlbNo
    ) &&
    !!royalAlbNo &&
    royalAlbNo.length > 6 &&
    royalAlbNo.length < 11
  ) {
    const emailValidation = Yup.object({
      email: Yup.string().email(),
      confirmEmail: Yup.string().email()
    });
    try {
      await emailValidation.validate({
        email: email,
        confirmEmail: confirmEmail
      });
      const traveler: ApiTraveler = await api.retrieveBusinessPartnerAsTraveler(
        ApiGetBusinessPartnerRequestFromJSON({
          businessPartnerNumber: royalAlbNo,
          userName: email
        })
      );
      if (!!traveler && !!participantsFormRefs.current[participantIndex]) {
        const participant = participantsFormRefs.current[participantIndex];
        participant.setValue('email', traveler.communicationDetails?.email || '');
        emailRef.current[participantIndex] = traveler.communicationDetails?.email || '';
        participant.setValue('confirmEmail', traveler.communicationDetails?.email || '');
        confirmEmailRef.current[participantIndex] = traveler.communicationDetails?.email || '';
        participant.setValue('title', traveler.title || traveler.salutation || '');
        participant.setValue('firstName', traveler.firstName || '');
        participant.setValue('lastName', traveler.lastName || '');
        participant.setValue('birthDate', traveler.birthDate || '');
        participant.setValue('street', traveler.address?.street || '');
        participant.setValue('zipCode', traveler.address?.postalCode || '');
        zipCodeRef.current[participantIndex] = traveler.address?.postalCode || '';
        participant.setValue('city', traveler.address?.city || '');
        participant.setValue('country', traveler.address?.countryCode || '');
        countryRef.current[participantIndex] = traveler.address?.countryCode || '';
        participant.setValue('phone', traveler.communicationDetails?.phone || '');
        if (!!traveler.address?.countryCode) {
          participant.setValue('countryCode', traveler.address?.countryCode || '');
          await findCountryCode(
            participant,
            traveler.address?.countryCode,
            countryCodeRef,
            participantIndex
          );
        }
      }
      if (!!traveler) {
        await checkoutStore.updateTravelers(traveler, participantIndex, royalAlbNo);
      }
    } catch (err) {
      logger('error')('Unable to fetch royal albatros data', err);
    }
  }
};

export const handleRemarksFormChange = async (
  formFields: Record<string, string>,
  setValue: UseFormSetValue<Record<string, unknown>>,
  setIsOnlinePaymentDisabled: (disabled: boolean) => void,
  remarksRef: MutableRefObject<string | undefined>
): Promise<void> => {
  if (!!remarksRef.current && !formFields?.notes) {
    setIsOnlinePaymentDisabled(false);
  } else if (!remarksRef.current && !!formFields?.notes) {
    setIsOnlinePaymentDisabled(true);
  }
  if (!!formFields?.notes && formFields.notes !== remarksRef.current) {
    setValue('notes', sanitizeString(formFields.notes));
  }
  remarksRef.current = formFields?.notes;
};
