import React, { Dispatch, FC, MutableRefObject, SetStateAction, useEffect } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import FlightIntro from '@/components/FlightIntro/FlightIntro';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { ApiAirlineLogo } from '../../../api/model';
import ProductSelection from '@/components/productSelection/ProductSelection';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import HotelSelection from '@/components/hotelSelection/HotelSelection';
import TransferSelection from '@/components/transferSelection/TransferSelection';
import OperaSelection from '@/components/operaSelection/OperaSelection';
import CheckoutStep from '@/components/checkout/CheckoutStep';
import classNames from 'classnames';
import BusSelection from '@/components/busSelection/BusSelection';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { CheckoutStepName, EventType } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { Props } from '@/types/cms/magnolia';
import { FormRefs } from '@/components/checkout/ParticipantsForm';
import { PacificProductForHeroDispatch } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';

const CheckoutStepHotel: FC<{
  checkoutStore: CheckoutStore;
  airlineLogos?: ApiAirlineLogo[];
  isDesktop: boolean;
  activeSubIndex: number[];
  activeIndex: number;
  numberOfSubStepsPerStep: number[];
  index: number;
  stepChangeTriggered: boolean;
  pageProps?: Props;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
  ignoreBusSeatValidation: boolean;
  participantsFormRefs: MutableRefObject<FormRefs>;
  setPacificProduct: PacificProductForHeroDispatch;
  consentGiven: boolean;
}> = observer(function CheckoutStepHotel({
  checkoutStore,
  airlineLogos,
  activeSubIndex,
  activeIndex,
  numberOfSubStepsPerStep,
  isDesktop,
  index,
  stepChangeTriggered,
  pageProps,
  setIgnoreBusSeatValidation,
  ignoreBusSeatValidation,
  participantsFormRefs,
  setPacificProduct,
  consentGiven
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const config = useConfig();
  const locale = useCurrentLanguage();

  useEffect(() => {
    if (index === activeIndex && !!checkoutStore.product && consentGiven) {
      broadcastEvent(EventType.CHECKOUT_PROGRESS, config, locale, {
        data: {
          product: checkoutStore.product,
          booking: checkoutStore.booking,
          cacheDataProduct: checkoutStore.selectedPacificProduct
        },
        checkout: { step: index + 1, stepName: CheckoutStepName.SERVICES }
      });
    }
  }, [index, activeIndex, checkoutStore.product, consentGiven]);

  return (
    <div className={classNames('mobile-slide', { 'mobile-slide--active': index === activeIndex })}>
      <div
        className="mobile-slide__inner"
        style={{
          width: isDesktop ? 'initial' : `${numberOfSubStepsPerStep[index] * 100}%`,
          transform: `translateX(${
            activeSubIndex[index] * (100 / numberOfSubStepsPerStep[index]) * -1
          }%)`
        }}
      >
        <CheckoutStep
          index={0}
          activeIndex={activeSubIndex[activeIndex]}
          numberOfItems={numberOfSubStepsPerStep[activeIndex]}
          baseClass="mobile-slide__step"
          ignore={isDesktop}
        >
          <GridContainer>
            <h2 className="checkout__headline">{t(Keys.participantsAndDepartures)}</h2>
            <p>{t(Keys.participantsAndDeparturesInfo)}</p>
            <ProductSelection
              checkoutStore={checkoutStore}
              participantsFormRefs={participantsFormRefs}
              setPacificProduct={setPacificProduct}
            />
          </GridContainer>
          {!checkoutStore.isLoading && !!checkoutStore.flights && checkoutStore.flights.length > 0 && (
            <GridContainer>
              <h2 className="checkout__headline">{t(Keys.flight)}</h2>
              <p>
                {checkoutStore.hasInvalidFlights()
                  ? t(Keys.flightInfoNoFlights)
                  : checkoutStore.hasFlightsWithStops()
                  ? t(Keys.flightInfoConnectingFlights)
                  : t(Keys.flightInfo)}
              </p>
              {!checkoutStore.hasInvalidFlights() && !!checkoutStore.flights && (
                <FlightIntro
                  checkoutStore={checkoutStore}
                  airlineLogos={airlineLogos}
                  pageProps={pageProps}
                />
              )}
            </GridContainer>
          )}
        </CheckoutStep>
        {checkoutStore.hotels?.length > 0 && (
          <CheckoutStep
            index={1}
            activeIndex={activeSubIndex[activeIndex]}
            numberOfItems={numberOfSubStepsPerStep[activeIndex]}
            baseClass="mobile-slide__step"
            ignore={isDesktop}
          >
            {checkoutStore.hotels?.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    checkoutStore.operas.length === 0 &&
                    checkoutStore.bus.length === 0 &&
                    checkoutStore.transfers.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.accommodation)}</h2>
                <p>{t(Keys.accommodationSubText)}</p>
                <HotelSelection checkoutStore={checkoutStore} pageProps={pageProps} />
              </GridContainer>
            )}
          </CheckoutStep>
        )}
        {(checkoutStore.operas.length > 0 ||
          checkoutStore.transfers.length > 0 ||
          checkoutStore.bus.length > 0) && (
          <CheckoutStep
            index={checkoutStore.hotels?.length === 0 ? 1 : 2}
            activeIndex={activeSubIndex[activeIndex]}
            numberOfItems={numberOfSubStepsPerStep[activeIndex]}
            baseClass="mobile-slide__step"
            ignore={isDesktop}
          >
            {checkoutStore.operas.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    checkoutStore.operas.length > 0 &&
                    checkoutStore.bus.length > 0 &&
                    checkoutStore.transfers.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.opera)}</h2>
                <p>{t(Keys.operaInfo)}</p>
                <OperaSelection checkoutStore={checkoutStore} pageProps={pageProps} />
              </GridContainer>
            )}
            {checkoutStore.bus.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    checkoutStore.bus.length > 0 && checkoutStore.transfers.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.bus)}</h2>
                <p>{t(Keys.busSubText)}</p>
                <BusSelection
                  checkoutStore={checkoutStore}
                  stepChangeTriggered={stepChangeTriggered}
                  pageProps={pageProps}
                  setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
                  ignoreBusSeatValidation={ignoreBusSeatValidation}
                />
              </GridContainer>
            )}
            {checkoutStore.transfers.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element': checkoutStore.transfers.length > 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.transfer)}</h2>
                <p>{t(Keys.transferSubText)}</p>
                <TransferSelection checkoutStore={checkoutStore} pageProps={pageProps} />
              </GridContainer>
            )}
          </CheckoutStep>
        )}
      </div>
    </div>
  );
});

export default CheckoutStepHotel;
