'use client';

import React, { FC, JSX, useEffect } from 'react';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { useWindow } from '@ibe/components';
import { usePathname, useSearchParams } from 'next/navigation';
import { PACKAGE_CART_ID_PARAMETER } from '@/Util/globals';
import { getInitialSelectedOptions } from '@/components/productSelection/optionsData';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import CheckoutPageInnerMarkup from '@/templates/checkout/CheckoutPageInnerMarkup';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import { BackendData } from '@/templates/checkout/useBackendData';
import { PacificProductForHeroDispatch } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';
import { observer } from 'mobx-react';
import { usePageViewEventOnCookieConsent } from '@/Hooks/usePageViewEventOnCookieConsent';
import { EventType } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';

const CheckoutPageInner: FC<{
  pageProps?: Props;
  showContactBar: boolean;
  siteConfig?: MagnoliaSite | null;
  rootNodePath?: string;
  externalLinks: ExternalLinks;
  useParticipantsMock: boolean;
  variantParam?: string;
  setPacificProduct: PacificProductForHeroDispatch;
  backendData: BackendData;
  checkoutStore: CheckoutStore;
}> = observer(function CheckoutPageInner({
  pageProps,
  backendData,
  showContactBar,
  siteConfig,
  rootNodePath,
  externalLinks,
  useParticipantsMock,
  variantParam,
  setPacificProduct,
  checkoutStore
}): JSX.Element {
  const config = useConfig();
  const pathname = usePathname();
  const window = useWindow();
  const searchParams = useSearchParams();

  const {
    packageCart,
    selectedPacificProduct,
    pacificProducts,
    baseData,
    product,
    booking,
    confirmationPageMode,
    displayUrl,
    packageCartId,
    airlineLogos
  } = backendData;

  useEffect(() => {
    if (!!packageCart) {
      checkoutStore.packageCart = packageCart;
    }
    checkoutStore.pacificProducts = pacificProducts;
    if (!!selectedPacificProduct) {
      checkoutStore.selectedPacificProduct = selectedPacificProduct;
    }
    if (!!product) {
      checkoutStore.product = product;
    }
    if (!!booking) {
      checkoutStore.booking = booking;
    }
    checkoutStore.setSelectedFilterOptions(
      getInitialSelectedOptions(selectedPacificProduct),
      setPacificProduct
    );
    checkoutStore.baseData = baseData;

    if (!!packageCart?.id) {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set(PACKAGE_CART_ID_PARAMETER, packageCart.id);
      const newUrl = `${pathname}?${newParams.toString()}`;
      window?.history?.replaceState(
        { ...window?.history?.state, as: newUrl, url: newUrl },
        '',
        newUrl
      );
    }
  }, [packageCart, pacificProducts, selectedPacificProduct, product, booking, baseData]);

  const consentGiven = usePageViewEventOnCookieConsent(
    pageProps,
    config,
    !!packageCart && !!selectedPacificProduct && !!product && !!booking,
    {
      pageProps,
      packageCart: packageCart,
      cacheDataProduct: selectedPacificProduct,
      product: product,
      booking: booking
    },
    [EventType.PAGE_VIEW, EventType.BEGIN_CHECKOUT]
  );

  return (
    <CheckoutPageInnerMarkup
      checkoutStore={checkoutStore}
      showContactBar={showContactBar}
      siteConfig={siteConfig}
      rootNodePath={rootNodePath}
      airlineLogos={airlineLogos}
      externalLinks={externalLinks}
      initialCartError={
        !packageCart?.id || !packageCart?.bookingId || !packageCart?.packageModel?.id
      }
      confirmationPageMode={confirmationPageMode}
      displayUrl={displayUrl}
      packageCartId={packageCartId}
      useParticipantsMock={useParticipantsMock}
      variantParam={variantParam}
      pageProps={pageProps}
      setPacificProduct={setPacificProduct}
      consentGiven={consentGiven}
    />
  );
});

export default CheckoutPageInner;
