/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-05 15:35:55 */

const Keys = {
  validationRequired: 'validationRequired',
  validationEmail: 'validationEmail',
  validationConfirmEmail: 'validationConfirmEmail',
  validationCheckboxGroupRequired: 'validationCheckboxGroupRequired',
  validationNumber: 'validationNumber',
  reopenFormButtonText: 'reopenFormButtonText'
}; export default Keys;