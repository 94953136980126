/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-05 15:35:55 */

const Keys = {
  notSupported: 'notSupported',
  downloadLink: 'downloadLink',
  scrollForMore: 'scrollForMore',
  date: 'date',
  duration: 'duration',
  price: 'price',
  departure: 'departure',
  language: 'language',
  days: 'days'
}; export default Keys;