import React, { Dispatch, FC, JSX, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/search.json.keys';
import Toggle from '@/components/Search/Toggle';
import { TFunction } from 'i18next';
import Button from '@/components/Button';
import classNames from 'classnames';
import SortDropdown from '@/components/SearchForResults/SortDropdown';
import { Spinner } from '@ibe/components';
import { useTransition } from 'react-transition-state';
import { getCheapestProduct, getShortestDurationProduct } from '@/components/Search/helpersProduct';
import { Props } from '@/types/cms/magnolia';
import { forceCheck } from 'react-lazyload';
import ResultsListTeaserView from '@/components/SearchForResults/ResultsListTeaserView';
import ResultsListCalendarView from '@/components/SearchForResults/ResultsListCalendarView';

const getViewLabels = (t: TFunction): string[] => {
  return [t(Keys.productImageView), t(Keys.mapView), t(Keys.calendarView)];
};

const getSortOptions = (t: TFunction): string[] => {
  return [
    `${t(Keys.price)} (${t(Keys.low)} - ${t(Keys.high)})`,
    `${t(Keys.price)} (${t(Keys.high)} - ${t(Keys.low)})`,
    `${t(Keys.duration)} (${t(Keys.low)} - ${t(Keys.high)})`
  ];
};

const ResultsList: FC<{
  productPackages: ApiProductsCacheResponse[];
  hideUnavailableTripsChecked: boolean;
  setHideUnavailableTripsChecked: Dispatch<SetStateAction<boolean>>;
  productPageBasePath: string;
  isLoading?: boolean;
  pageProps?: Props;
}> = ({
  productPackages,
  hideUnavailableTripsChecked,
  setHideUnavailableTripsChecked,
  productPageBasePath,
  isLoading,
  pageProps
}): JSX.Element => {
  const { t } = useTranslation('search');

  const [activeView, setActiveView] = useState<number>(0);
  const [selectedSortOption, setSelectedSortOption] = useState<number>(0);

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 250,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggle(isLoading);
  }, [isLoading]);

  const sortedPackages = useMemo((): ApiProductsCacheResponse[] => {
    return productPackages.sort((a: ApiProductsCacheResponse, b: ApiProductsCacheResponse) => {
      if (selectedSortOption === 0) {
        return (
          (getCheapestProduct(a)?.startingPriceAmount || 0) -
          (getCheapestProduct(b)?.startingPriceAmount || 0)
        );
      } else if (selectedSortOption === 1) {
        return (
          (getCheapestProduct(b)?.startingPriceAmount || 0) -
          (getCheapestProduct(a)?.startingPriceAmount || 0)
        );
      } else if (selectedSortOption === 2) {
        return (
          (getShortestDurationProduct(a)?.duration || 0) -
          (getShortestDurationProduct(b)?.duration || 0)
        );
      } else {
        return 0;
      }
    });
  }, [productPackages, selectedSortOption]);

  useEffect(() => {
    const timer = setTimeout((): void => {
      forceCheck();
    }, 300);

    return (): void => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [sortedPackages]);

  return (
    <div className="results__list">
      <div className="results__list__head">
        <div className="results__list__head__inner">
          <div className="results__list__found">
            {t(Keys.foundTrips, { count: productPackages.length })}
          </div>
          <Toggle
            className="results__list__toggle"
            label={t(Keys.hideUnavailableTrips)}
            checked={hideUnavailableTripsChecked}
            onChange={(): void => setHideUnavailableTripsChecked(value => !value)}
          />
        </div>
        <div className="results__list__head__inner">
          {activeView !== 2 && (
            <div className="results__list__sort--mobile">
              <SortDropdown
                options={getSortOptions(t)}
                onSelect={setSelectedSortOption}
                selectedOption={selectedSortOption}
              />
            </div>
          )}
          <div className="results__list__head__views">
            {getViewLabels(t).map((label: string, idx: number) => (
              <Button
                key={label}
                color="primary"
                className={classNames('results__list__head__button', {
                  'results__list__head__button--active': idx === activeView
                })}
                onClick={(): void => setActiveView(idx)}
              >
                {label}
              </Button>
            ))}
          </div>
        </div>
      </div>
      {activeView !== 2 && (
        <div className="results__list__sort--desktop">
          <SortDropdown
            options={getSortOptions(t)}
            onSelect={setSelectedSortOption}
            selectedOption={selectedSortOption}
          />
        </div>
      )}
      <div
        className={classNames('results__list__content', {
          'results__list__content--calendar': activeView === 2
        })}
      >
        <ResultsListTeaserView
          activeView={activeView}
          pageProps={pageProps}
          productPageBasePath={productPageBasePath}
          sortedPackages={sortedPackages}
        />
        {productPackages.length > 0 && activeView === 2 && (
          <ResultsListCalendarView
            productPackages={productPackages}
            hideUnavailableTripsChecked={hideUnavailableTripsChecked}
            productPageBasePath={productPageBasePath}
            pageProps={pageProps}
          />
        )}
      </div>
      {isMounted && (
        <div
          className={classNames('results__list__loading', {
            'results__list__loading--hidden': status === 'preEnter' || status === 'exiting'
          })}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default ResultsList;
