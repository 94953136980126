import { createInstance, i18n, TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import { getOptions } from './settings';
import ChainedBackend from 'i18next-chained-backend';

const initI18next = async (
  lng: string,
  ns?: string,
  translationsVersion?: string,
  activateCache?: boolean
): Promise<i18n> => {
  const i18nInstance = createInstance();
  await i18nInstance
    .use(initReactI18next)
    .use(ChainedBackend)
    .init(getOptions(lng, ns, undefined, undefined, translationsVersion, activateCache));
  return i18nInstance;
};

export const getTranslation = async (
  lng: string,
  ns: string,
  options: { keyPrefix?: string; translationsVersion?: string; activateCache?: boolean } = {
    translationsVersion: '0'
  }
): Promise<{ t: TFunction; i18n: i18n }> => {
  const { keyPrefix, translationsVersion, activateCache } = options;
  const i18nextInstance = await initI18next(lng, ns, translationsVersion, activateCache);
  return {
    t: i18nextInstance.getFixedT(lng, Array.isArray(ns) ? ns[0] : ns, keyPrefix),
    i18n: i18nextInstance
  };
};
