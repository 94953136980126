import React, { FC, Fragment, JSX, useMemo } from 'react';
import { ApiWebsiteOtherPagesSearch } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link as NextLink } from '@/Hooks/useReactTransitionProgress';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';
import LazyLoad from 'react-lazyload';
import FadeIn from '@/components/SearchForResults/FadeIn';

const WebsiteSearchFullOtherPagesList: FC<{
  results: ApiWebsiteOtherPagesSearch[];
  activeIndex: number;
}> = ({ results, activeIndex }): JSX.Element => {
  const { t } = useTranslation('Header');

  const groupByPageType = useMemo(() => {
    let map: Record<string, ApiWebsiteOtherPagesSearch[]> = {};
    const sortedByPriority = results.sort((a, b) => {
      if (a.priority && b.priority) {
        return b.priority - a.priority;
      } else {
        return 0;
      }
    });
    sortedByPriority.forEach((item: ApiWebsiteOtherPagesSearch) => {
      map[item.pageType] = [...(map[item.pageType] || []), item];
    });
    return Object.entries(map).sort();
  }, [results]);

  return (
    <div
      style={activeIndex !== 2 ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list"
    >
      {groupByPageType.map(([key, value]) => (
        <Fragment key={key}>
          <h4 key={key}>{key}</h4>
          {value.map(item => (
            <LazyLoad key={item.title} throttle={300} offset={400} height={420} scroll resize>
              <FadeIn>
                <div key={item.title} className="website-search-full__results__list--row">
                  <b>{item.title}</b>
                  <NextLink prefetch={false} href={item.url}>
                    <span>{t(Keys.learnMore)}</span>
                    <FontAwesomeIcon icon={faCircleArrowRight} />
                  </NextLink>
                  <p>{item.matchedText}</p>
                </div>
              </FadeIn>
            </LazyLoad>
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default WebsiteSearchFullOtherPagesList;
