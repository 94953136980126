import React, { JSX } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import Intro from '@/components/intro/Intro';
import { getTranslation } from '@/app/i18n';
import { THEMES } from '@/Util/globals';
import { ApiCountry } from '../../../api/model';
import sanitize from '@/Util/sanitize';
import { Props } from '@/types/cms/magnolia';

const CountryFacts = async ({
  country,
  language: locale,
  pageProps,
  pathname
}: {
  country?: ApiCountry | null;
  language: string;
  pageProps?: Props;
  pathname: string;
}): Promise<JSX.Element> => {
  const {
    introTitleFacts,
    introTextFacts,
    capitalCity,
    citizens,
    currency,
    language,
    religion,
    area,
    themeIntroFacts
  } = country || {};
  const { t } = await getTranslation(locale, 'country', {
    translationsVersion: pageProps?.translationsVersion,
    activateCache: pageProps?.activateCache
  });

  return (
    <Intro
      headline={introTitleFacts ? introTitleFacts : `${t(Keys.headlineFacts)} ${country?.name}`}
      pageProps={pageProps}
      pathname={pathname}
      leftSide={
        <div className="mb-xs-3 mb-md-0">
          {!!introTextFacts && (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(introTextFacts, true)
              }}
            />
          )}
        </div>
      }
      rightSide={
        <>
          <div>
            <div className="d-sm-flex mb-sm-4">
              {!!citizens && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.citizens)}</div>
                  <div className="intro__container__text">{citizens}</div>
                </div>
              )}
              {!!capitalCity && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.capitalCity)}</div>
                  <div className="intro__container__text">{capitalCity}</div>
                </div>
              )}
            </div>
            <div className="d-sm-flex mb-sm-4">
              {!!religion && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.religion)}</div>
                  <div className="intro__container__text">{religion}</div>
                </div>
              )}
              {!!currency && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.currency)}</div>
                  <div className="intro__container__text">{currency}</div>
                </div>
              )}
            </div>
            <div className="d-sm-flex mb-sm-4">
              {!!language && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.language)}</div>
                  <div className="intro__container__text">{language}</div>
                </div>
              )}
              {!!area && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.area)}</div>
                  <div className="intro__container__text">{area}</div>
                </div>
              )}
            </div>
          </div>
        </>
      }
      theme={themeIntroFacts || THEMES.blue}
    />
  );
};

export default CountryFacts;
