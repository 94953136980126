import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { facBurger /*facProfile, facSearch*/ } from '@/Theme/SVG/Icons';
// import Keys from '@/Translations/generated/da/Header.json.keys';
import { HeaderProps } from '@/Layouts/Header/external';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useTranslation } from '@/app/i18n/client';
import MobileOverlay from '@/components/MobileOverlay/MobileOverlay';
import { Link } from '@/Hooks/useReactTransitionProgress';

const MenuMobile = ({
  burgerOpen,
  setBurgerOpen,
  data
}: {
  burgerOpen: boolean;
  setBurgerOpen: Dispatch<SetStateAction<boolean>>;
  data: HeaderProps;
}): ReactElement => {
  // const { t } = useTranslation('Header');

  return (
    <MobileOverlay
      overlayOpen={burgerOpen}
      setOverlayOpen={setBurgerOpen}
      ignoreNoZIndex
      headerContent={
        <div className="overlay-header__inner">
          {/* TODO: MyAlbatros */}
          {/*<a className="burger-header-link menu" href="#">*/}
          {/*  <FontAwesomeIcon icon={facProfile} />*/}
          {/*  <span className="link-text">{t(Keys.withAlbatros)}</span>*/}
          {/*</a>*/}
        </div>
      }
      content={
        <>
          <div className="burger-topnav-link-wrapper">
            {data.navLinks.map(navLink => {
              return (
                <div className="burger-topnav-link" key={navLink.label}>
                  <Link
                    className="burger-content-link menu"
                    key={navLink.label}
                    href={navLink.linkProps.href}
                  >
                    {navLink.label}
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="grid burger-sidenav-wrapper">
            <div className="grid-col-6">
              {data.sidebarNavLinksLeftColumn.map(sidebarNavLinks => {
                return (
                  <div className="burger-sidenav-link" key={sidebarNavLinks.label}>
                    <Link
                      className="burger-content-link menu"
                      key={sidebarNavLinks.label}
                      href={sidebarNavLinks.linkProps.href}
                    >
                      {sidebarNavLinks.label}
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="grid-col-5">
              {data.sidebarNavLinksRightColumn.map(sidebarNavLinks => {
                return (
                  <div className="burger-sidenav-link" key={sidebarNavLinks.label}>
                    <Link
                      className="burger-content-link menu"
                      key={sidebarNavLinks.label}
                      href={sidebarNavLinks.linkProps.href}
                    >
                      {sidebarNavLinks.label}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="burger-footer">
            <hr />
            <div className="burger-footer-content">
              <span className="burger-footer-info">{data.open}</span> <br />
              <span className="burger-footer-info">{data.tel}</span> <br />
              <span className="burger-footer-info">{data.mail}</span> <br />
            </div>
          </div>
        </>
      }
      onCloseIcon={<FontAwesomeIcon icon={facBurger} />}
    />
  );
};

export default MenuMobile;
