'use client';

import React, { Dispatch, FC, JSX, SetStateAction, useEffect, useState } from 'react';
import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/product.json.keys';
import GridContainer from '@/Layouts/GridContainer';
import DatesPricesContent from '@/components/ProductsDatesPrices/DatesPricesContent';
import { useRouter, useSearchParams } from 'next/navigation';
import { ApiProduct } from '../../../api/model';
import { MagnoliaSite } from '@/types/cms/magnolia';
import { CHECKOUT_PARAMS_PARAMETER } from '@/Util/globals';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useWindow } from '@ibe/components';
import classNames from 'classnames';
import SuspenseFallbackComponent from '@/components/SuspenseFallbackComponent/SuspenseFallbackComponent';

export const prepareCheckoutParamsData = (
  packageId: string,
  displayUrl: string,
  productCode: string,
  cacheDataProductCode: string,
  variantParam?: string,
  window?: Window | null
): string | undefined => {
  return window?.btoa(
    JSON.stringify({
      packageId,
      displayUrl,
      productCode,
      cacheDataProductCode,
      variantParam
    })
  );
};

export const goToCheckout = (
  item: ApiProductsCacheData,
  checkoutPageUrl: string,
  router: AppRouterInstance,
  product?: ApiProduct,
  window?: Window | null,
  routeChangeCallback?: () => void,
  variantParam?: string,
  returnUrl?: boolean
): string => {
  if (!!checkoutPageUrl) {
    const checkoutParamsData = prepareCheckoutParamsData(
      item.packageId || '',
      product?.displayUrl || '',
      product?.name || '',
      item.packageCode || '',
      variantParam,
      window
    );

    if (!!checkoutParamsData) {
      const currentSearchParams = new URLSearchParams();
      currentSearchParams.set(CHECKOUT_PARAMS_PARAMETER, checkoutParamsData);
      const query = !!currentSearchParams.toString() ? `?${currentSearchParams.toString()}` : '';
      if (!!routeChangeCallback) {
        routeChangeCallback();
      }
      if (returnUrl) {
        return `${checkoutPageUrl}${query}`;
      } else {
        router.push(`${checkoutPageUrl}${query}`);
      }
    }
  }
  return '';
};

const ProductsDatesPrices: FC<{
  productPackages: ApiProductsCacheResponse[];
  checkoutPageUrl: string;
  siteConfig?: MagnoliaSite;
  isVariant?: boolean;
  product?: ApiProduct;
  originalProduct?: ApiProduct;
  selectedPackage?: ApiProductsCacheResponse;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  theme?: string;
}> = ({
  productPackages,
  checkoutPageUrl,
  siteConfig,
  isVariant,
  product,
  selectedPackage,
  setIsLoading,
  theme,
  originalProduct
}): JSX.Element => {
  const router = useRouter();
  const window = useWindow();
  const { t } = useTranslation('product');
  const [loading, setLoading] = useState<boolean>(false);
  const searchParams = useSearchParams();

  useEffect(() => {
    setLoading(false);
  }, [searchParams]);

  const prepareCheckout = (item: ApiProductsCacheData, variantParam?: string): void => {
    goToCheckout(
      item,
      checkoutPageUrl,
      router,
      originalProduct,
      window,
      (): void => setIsLoading(true),
      variantParam
    );
  };

  return (
    <GridContainer className={classNames('dates-prices', theme || 'theme-sand')}>
      <h2>{t(Keys.departureDates)}</h2>
      <DatesPricesContent
        productPackages={productPackages}
        goToCheckout={prepareCheckout}
        siteConfig={siteConfig}
        isVariant={isVariant}
        product={product}
        originalProduct={originalProduct}
        selectedPackage={selectedPackage}
        setLoading={setLoading}
      />
      {loading && <SuspenseFallbackComponent logoOnly fitToContainer spinnerOnly />}
    </GridContainer>
  );
};

export default ProductsDatesPrices;
