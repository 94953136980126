'use client';

import React, { FC, JSX } from 'react';
import { ApiProductsCacheData } from '@ibe/api';
import { ApiProduct } from '../../../api/model';
import HeroPacificProductInfos from '@/components/checkout/HeroPacificProductInfos';

const CheckoutPageHeroContent: FC<{
  selectedPacificProduct: ApiProductsCacheData;
  product?: ApiProduct;
  locale: string;
  name?: string;
}> = ({ product, selectedPacificProduct, name }): JSX.Element => {
  return (
    <div className="checkout__infos">
      <h1>{name || product?.productTitle || ''}</h1>
      <HeroPacificProductInfos pacificProduct={selectedPacificProduct} />
    </div>
  );
};

export default CheckoutPageHeroContent;
