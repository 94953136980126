import React, { Dispatch, FC, JSX, SetStateAction, useRef, useState } from 'react';
import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { ApiProduct } from '../../../api/model';
import { MagnoliaSite } from '@/types/cms/magnolia';
import MobileContent from '@/components/ProductsDatesPrices/MobileContent';
import DesktopContent from '@/components/ProductsDatesPrices/DesktopContent';
import Filters from '@/components/ProductsDatesPrices/Filters';
import { getSortedCacheProductsByDate } from '@/components/SearchForResults/helperFns';

dayjs.extend(localeData);

const DatesPricesContent: FC<{
  productPackages: ApiProductsCacheResponse[];
  goToCheckout: (item: ApiProductsCacheData, variantParam?: string) => void;
  siteConfig?: MagnoliaSite;
  isVariant?: boolean;
  product?: ApiProduct;
  originalProduct?: ApiProduct;
  selectedPackage?: ApiProductsCacheResponse;
  setLoading: Dispatch<SetStateAction<boolean>>;
}> = ({
  productPackages,
  goToCheckout,
  siteConfig,
  isVariant,
  product,
  selectedPackage,
  originalProduct,
  setLoading
}): JSX.Element => {
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });
  const containerRef = useRef<HTMLDivElement>(null);

  const [departureAirport, setDepartureAirport] = useState<string>('');

  return (
    <div ref={containerRef}>
      <Filters
        departureAirport={departureAirport}
        setDepartureAirport={setDepartureAirport}
        productPackages={productPackages}
      />
      {!isDesktop ? (
        <MobileContent
          sortedProducts={getSortedCacheProductsByDate(productPackages)}
          isVariant={isVariant}
          isDesktop={isDesktop}
          containerRef={containerRef}
          goToCheckout={goToCheckout}
          siteConfig={siteConfig}
          product={product}
          selectedPackage={selectedPackage}
          originalProduct={originalProduct}
          setLoading={setLoading}
          departureAirport={departureAirport}
        />
      ) : (
        <DesktopContent
          sortedProducts={getSortedCacheProductsByDate(productPackages)}
          isVariant={isVariant}
          isDesktop={isDesktop}
          containerRef={containerRef}
          goToCheckout={goToCheckout}
          siteConfig={siteConfig}
          product={product}
          selectedPackage={selectedPackage}
          originalProduct={originalProduct}
          setLoading={setLoading}
          departureAirport={departureAirport}
        />
      )}
    </div>
  );
};

export default DatesPricesContent;
