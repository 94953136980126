/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-05 15:35:55 */

const Keys = {
  priceInformationHeadline: 'priceInformationHeadline',
  introduction: 'introduction',
  images: 'images',
  extensionTeaser: 'extensionTeaser',
  departureDates: 'departureDates',
  itinerary: 'itinerary',
  contentArea: 'contentArea',
  priceInformation: 'priceInformation',
  travelInformation: 'travelInformation',
  goToOrder: 'goToOrder',
  newJourney: 'newJourney',
  newJourneyUpperCase: 'newJourneyUpperCase',
  seeAvailableDates: 'seeAvailableDates',
  readMore: 'readMore',
  showLess: 'showLess',
  gallery: 'gallery',
  address: 'address',
  moreInformation: 'moreInformation',
  hotels: 'hotels',
  multipleHotelsIntro: 'multipleHotelsIntro',
  days: 'days',
  similarTrips: 'similarTrips',
  departure: 'departure',
  price: 'price',
  duration: 'duration',
  departureFrom: 'departureFrom',
  note: 'note',
  note2: 'note2',
  clickInfoIcon: 'clickInfoIcon',
  clickTableRow: 'clickTableRow',
  day: 'day',
  day_plural: 'day_plural',
  singleDay: 'singleDay',
  from: 'from',
  book: 'book',
  soldOut: 'soldOut',
  noAvailableDates: 'noAvailableDates',
  departureAirports: 'departureAirports',
  numberOfPeople: 'numberOfPeople',
  reset: 'reset',
  dailyProgram: 'dailyProgram',
  downloadFullProgram: 'downloadFullProgram',
  trips: 'trips',
  hotel: 'hotel',
  camp: 'camp',
  plane: 'plane',
  train: 'train',
  bus: 'bus',
  cruise: 'cruise',
  goToShip: 'goToShip',
  mapText: 'mapText',
  reviews: 'reviews',
  guestReviews: 'guestReviews',
  departed: 'departed',
  travelInformationHeadline: 'travelInformationHeadline',
  travelInformationText: 'travelInformationText',
  almostSoldOut: 'almostSoldOut',
  select: 'select',
  highlights: 'highlights',
  interestList: 'interestList',
  interestListHeadline: 'interestListHeadline',
  interestListSubHeadline: 'interestListSubHeadline',
  trip: 'trip',
  interestListTextOnProductPage: 'interestListTextOnProductPage'
}; export default Keys;