import React, { FC, JSX } from 'react';
import Hero from '@/components/hero/Hero';
import { ApiProductsCacheData } from '@ibe/api';
import { ApiProduct } from '../../../api/model';
import CheckoutPageHeroClient from '@/templates/checkout/CheckoutPageHeroClient';
import CheckoutPageHeroContent from '@/templates/checkout/CheckoutPageHeroContent';
import { Props } from '@/types/cms/magnolia';

const CheckoutPageHero: FC<{
  selectedPacificProduct: ApiProductsCacheData;
  product?: ApiProduct;
  name?: string;
  locale: string;
  pageProps?: Props;
}> = ({ product, selectedPacificProduct, locale, pageProps, name }): JSX.Element => {
  return !!product?.teaserImageLarge ? (
    <Hero
      content="image"
      contentimage={product?.teaserImageLarge}
      useVideoScaleEffect
      showSearchPanel={false}
      hideScrollBtn
      useDefaultLoader
    >
      <CheckoutPageHeroContent
        selectedPacificProduct={selectedPacificProduct}
        product={product}
        locale={locale}
        name={name}
      />
    </Hero>
  ) : (
    <CheckoutPageHeroClient pageProps={pageProps}>
      <CheckoutPageHeroContent
        selectedPacificProduct={selectedPacificProduct}
        product={product}
        locale={locale}
        name={name}
      />
    </CheckoutPageHeroClient>
  );
};

export default CheckoutPageHero;
