import React, { Dispatch, FC, JSX, RefObject, SetStateAction } from 'react';
import AccordionElementList from '@/components/accordion/AccordionElementList';
import { ApiProductsCacheResponse } from '@ibe/api';
import { MagnoliaSite } from '@/types/cms/magnolia';
import { ApiProduct } from '../../../api/model';
import {
  SortedCacheProductsByDate,
  YEAR_MONTH_DELIMITER
} from '@/components/SearchForResults/helperFns';
import MobileContentItem from '@/components/ProductsDatesPrices/MobileContentItem';
import {
  getVariantUrl,
  packageHasFutureDates
} from '@/components/ProductsDatesPrices/DesktopContent';
import useMonthLabels from '@/Hooks/useMonthLabels';

const MobileContent: FC<{
  sortedProducts: SortedCacheProductsByDate;
  siteConfig?: MagnoliaSite;
  isVariant?: boolean;
  product?: ApiProduct;
  originalProduct?: ApiProduct;
  isDesktop: boolean;
  containerRef: RefObject<HTMLDivElement>;
  goToCheckout: (item: ApiProductsCacheResponse, variantParam?: string) => void;
  selectedPackage?: ApiProductsCacheResponse;
  setLoading: Dispatch<SetStateAction<boolean>>;
  departureAirport: string;
}> = ({
  sortedProducts,
  siteConfig,
  containerRef,
  goToCheckout,
  isDesktop,
  isVariant,
  product,
  selectedPackage,
  originalProduct,
  setLoading,
  departureAirport
}): JSX.Element => {
  const monthLabels = useMonthLabels(true);

  return (
    <div className="dates-prices--mobile">
      <AccordionElementList
        items={Object.entries(sortedProducts).filter(([, productPackages]) =>
          productPackages.some(
            productPackage =>
              !departureAirport ||
              productPackage.products?.[0]?.departureAirport === departureAirport
          )
        )}
        headerCb={([year_month]) => {
          const year = year_month.split(YEAR_MONTH_DELIMITER)[0];
          const month = year_month.split(YEAR_MONTH_DELIMITER)[1];
          return <h5>{`${monthLabels[parseInt(month, 10)]} ${year}`}</h5>;
        }}
        bodyCb={([, products]) => (
          <>
            {products
              .filter(
                productPackage =>
                  packageHasFutureDates(productPackage) &&
                  (!departureAirport ||
                    productPackage.products?.[0]?.departureAirport === departureAirport)
              )
              .map(productPackage => (
                <MobileContentItem
                  key={`${productPackage.packageCode}-${productPackage.products?.[0]?.travelStartDate}`}
                  productPackage={productPackage}
                  isVariant={isVariant}
                  isDesktop={isDesktop}
                  containerRef={containerRef}
                  goToCheckout={goToCheckout}
                  siteConfig={siteConfig}
                  product={product}
                  selectedPackage={selectedPackage}
                  originalProduct={originalProduct}
                  variantUrl={getVariantUrl(productPackage, originalProduct, product, siteConfig)}
                  setLoading={setLoading}
                />
              ))}
          </>
        )}
      />
    </div>
  );
};

export default MobileContent;
