import { Props } from '@/types/cms/magnolia';
import {
  ApiBaseData,
  ApiBooking,
  ApiComponentType,
  ApiConfigurationDataType,
  ApiFlightItem,
  ApiGetPackageCartStartOptionsFromJSON,
  ApiItemType,
  ApiPackageCart,
  ApiProductsCacheData,
  ApiProductsCacheDataFromJSON,
  ApiProductsCacheRequestFromJSON
} from '@ibe/api';
import { ApiAirlineLogo, ApiProduct } from '../../../api/model';
import { useEffect, useState } from 'react';
import { useModelApiClient } from '@/Util/api';
import {
  CHECKOUT_BOOKING_ID_QUERY_PARAM,
  CHECKOUT_PARAMS_PARAMETER,
  CHECKOUT_STATUS_QUERY_PARAM,
  getCountryCodeFromMarket,
  IGNORED_BASE_DATA_SALUTATIONS,
  logger,
  PACKAGE_CART_ID_PARAMETER,
  VIP_COUNTRIES
} from '@/Util/globals';
import { useApi } from '@/Hooks/useApi';
import { fallbackLng } from '@/app/i18n/settings';
import { CheckoutParams } from '@/templates/checkout/CheckoutPageClient';
import { useClientSideBootingFinished } from '@/Hooks/useClientSideBootingFinished';
import { ReadonlyURLSearchParams } from 'next/navigation';

export type BaseData = {
  salutations: ApiBaseData[];
  countries: (ApiBaseData & { isDisabled?: boolean })[];
};

export type BackendData = {
  pacificProducts: ApiProductsCacheData[];
  selectedPacificProduct: ApiProductsCacheData;
  product?: ApiProduct;
  packageCart?: ApiPackageCart;
  booking?: ApiBooking;
  airlineLogos?: ApiAirlineLogo[];
  baseData: BaseData;
  packageCartId: string;
  displayUrl: string;
  variantParam: string;
  confirmationPageMode: boolean;
  locale: string;
};

let defaultBackendData = {
  pacificProducts: [],
  selectedPacificProduct: ApiProductsCacheDataFromJSON({}),
  product: undefined,
  packageCart: undefined,
  booking: undefined,
  airlineLogos: undefined,
  baseData: { salutations: [], countries: [] },
  packageCartId: '',
  displayUrl: '',
  variantParam: '',
  confirmationPageMode: false,
  locale: fallbackLng
};

const useBackendData = (
  searchParams: ReadonlyURLSearchParams,
  isAuthor?: boolean,
  pageProps?: Props
): { backendData?: BackendData; isLoading: boolean } => {
  const modelApi = useModelApiClient(isAuthor);
  const api = useApi(isAuthor);
  const clientSideBootingFinished = useClientSideBootingFinished();
  const [backendData, setBackendData] = useState<BackendData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (!clientSideBootingFinished) return;
      const { locale: localeFromProps, siteConfig } = pageProps || {};
      let localBackendData: BackendData = { ...defaultBackendData };

      const locale = localeFromProps || fallbackLng;
      localBackendData.locale = locale;
      let packageId = '';
      let productCode = '';
      let cacheDataProductCode = '';
      const checkoutParams = searchParams.get(CHECKOUT_PARAMS_PARAMETER) || '';
      if (!!checkoutParams) {
        const {
          packageId: localPackageId,
          displayUrl: localDisplayUrl,
          productCode: localProductCode,
          cacheDataProductCode: localCacheDataProductCode,
          variantParam: localVariantParam
        } = (JSON.parse(window?.atob(checkoutParams)) as CheckoutParams | undefined) || {};
        localBackendData.displayUrl = localDisplayUrl || '';
        localBackendData.variantParam = localVariantParam || '';
        packageId = localPackageId || '';
        productCode = localProductCode || '';
        cacheDataProductCode = localCacheDataProductCode || '';
      }

      const bookingId = searchParams.get(CHECKOUT_BOOKING_ID_QUERY_PARAM);
      const status = searchParams.get(CHECKOUT_STATUS_QUERY_PARAM);
      if (!!bookingId && !!status) {
        defaultBackendData = { ...defaultBackendData, confirmationPageMode: true };
        setIsLoading(false);
        setBackendData({ ...defaultBackendData });
        return;
      }

      try {
        if (!!productCode) {
          const products = await modelApi.getProductListByCodes(
            [productCode],
            siteConfig?.name || '',
            locale
          );
          localBackendData.product = products?.[0];
          if (!!cacheDataProductCode && !!localBackendData.product) {
            const foundProduct =
              localBackendData.product.variants?.find(
                variant => variant.name === cacheDataProductCode
              ) || localBackendData.product;
            localBackendData.product = {
              ...foundProduct,
              variants: localBackendData.product.variants || [],
              displayUrl: localBackendData.product.displayUrl,
              isManualTour: foundProduct.isManualTour
            } as ApiProduct | undefined;
          }
        }
      } catch (err) {
        logger('error')('Failed to fetch product data: ', err);
      }

      try {
        if (!!localBackendData.product) {
          localBackendData.pacificProducts =
            (
              await api.getProductsList(
                ApiProductsCacheRequestFromJSON({
                  productCodes: localBackendData.product.isManualTour
                    ? []
                    : [localBackendData.product.name],
                  manualProductCodes: localBackendData.product.isManualTour
                    ? [localBackendData.product.name]
                    : undefined,
                  languageCode: locale
                })
              )
            )?.[0]?.products || [];
        }
        if (!!localBackendData.pacificProducts) {
          localBackendData.selectedPacificProduct =
            localBackendData.pacificProducts.find(
              pacificProduct => pacificProduct.packageId === packageId
            ) || localBackendData.selectedPacificProduct;
        }
      } catch (err) {
        logger('error')('Unable to fetch products: ', err);
      }

      try {
        if (!!localBackendData.selectedPacificProduct.packageId) {
          localBackendData.packageCartId = searchParams.get(PACKAGE_CART_ID_PARAMETER) || '';
          if (!!localBackendData.packageCartId) {
            try {
              localBackendData.packageCart = await api.load(localBackendData.packageCartId);
            } catch (err) {
              logger('error')('Failed to load package cart, starting new one instead: ', err);
              localBackendData.packageCart = await api.start(
                localBackendData.selectedPacificProduct.packageId,
                'EMPTY',
                true,
                'EMPTY',
                ApiGetPackageCartStartOptionsFromJSON({
                  site: siteConfig?.name || '',
                  language: locale
                })
              );
            }
          } else {
            localBackendData.packageCart = await api.start(
              localBackendData.selectedPacificProduct.packageId,
              'EMPTY',
              true,
              'EMPTY',
              ApiGetPackageCartStartOptionsFromJSON({
                site: siteConfig?.name || '',
                language: locale
              })
            );
          }
        }
      } catch (err) {
        logger('error')('Failed to start package cart: ', err);
      }

      try {
        if (!!localBackendData.packageCart?.bookingId) {
          localBackendData.booking = await api.getBooking(
            localBackendData.packageCart?.bookingId,
            false
          );
        }
      } catch (err) {
        logger('error')('Failed to fetch booking: ', err);
      }

      try {
        if (!!localBackendData.packageCart) {
          const airlineCarriers = (localBackendData.packageCart?.packageModel?.packageDetails?.[0]?.components
            ?.filter(
              item =>
                item.itemType === ApiItemType.FLIGHT &&
                item.componentType === ApiComponentType.REQUIRED
            )
            ?.flatMap(component => component?.selectedItems) as ApiFlightItem[])
            ?.flatMap(flight => flight.segment)
            ?.flatMap(segment => segment.legs)
            ?.map(leg => leg.operatingCarrier.code || leg.marketingCarrier.code)
            ?.filter((code, idx, all) => all.indexOf(code) === idx);
          if (airlineCarriers?.length > 0) {
            localBackendData.airlineLogos = await modelApi.getAirlineLogosByCode([
              ...airlineCarriers
            ]);
          }
        }
      } catch (err) {
        logger('error')('Failed to fetch airline logos data: ', err);
      }

      try {
        const response = await Promise.all([api.getSalutations(), api.getAllCountries(locale)]);
        let countries = (response[1] || []).filter(
          country => !VIP_COUNTRIES.includes(country.code)
        );
        let vipCountries = (response[1] || [])
          .filter(country => VIP_COUNTRIES.includes(country.code))
          .sort((a, b) => a.code.localeCompare(b.code));
        const marketCountry = vipCountries.find(
          country => country.code === getCountryCodeFromMarket(siteConfig?.name || '')
        );
        if (!!marketCountry) {
          const marketCountryIndex = vipCountries.indexOf(marketCountry);
          vipCountries.splice(marketCountryIndex, 1);
          vipCountries = [marketCountry, ...vipCountries];
        }
        localBackendData.baseData = {
          salutations: (response[0].data[ApiConfigurationDataType.SALUTATIONS] || []).filter(
            data => !IGNORED_BASE_DATA_SALUTATIONS.includes(data.code)
          ),
          countries: [
            ...vipCountries,
            { code: '-', description: '----------------', isDisabled: true },
            ...countries
          ]
        };
      } catch (err) {
        logger('error')('Failed to fetch base data: ', err);
      }
      setBackendData({ ...localBackendData });
      setIsLoading(false);
    })();
  }, [clientSideBootingFinished]);

  return { backendData, isLoading };
};

export default useBackendData;
